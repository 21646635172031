import { Component, Input, OnInit } from '@angular/core';
import { Advert, Content, ContentArea } from '../models/advert.model';
import { AdvertService } from '../Services/advert.service';
import { Browser } from '@capacitor/browser';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';

@Component({
  templateUrl: '../views/advert.html',
  selector: 'app-advert',
  styleUrls: ['../styles/advert.scss'],
})
export class AdvertPage implements OnInit {
  ads!: Advert[];
  contents: Content[] = [];
  slideOptions = {
    initialSlide: 1,
    speed: 400,
    autoPlay: true,
  };
  constructor(
    private advertService: AdvertService,
    private router: Router,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.auth.currentUser$.subscribe((user) => {
      if (user.isAuthenticated) {
        this.advertService
          .getContent(ContentArea.Page)
          .subscribe((contents) => {
            this.contents = contents;
          });
      }
    });
  }

  async select(content: Content) {
    if (!content.isExternalLink) return this.router.navigateByUrl(content.link);
    await Browser.open({
      url: content.link,
      windowName: '_self',
    });
    await Browser.addListener('browserFinished', () => {});
    return;
  }
}
