import { Directive,Output, EventEmitter, HostBinding, HostListener, HostBindingDecorator, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { IonItem } from '@ionic/angular';

@Directive({
  selector: '[swipeItem]'
})
export class SwipeItemDirective {
	item!: ElementRef;
	@ViewChild(IonItem, { read: ElementRef }) testItem!: ElementRef;

  constructor(private el: ElementRef,
    private renderer: Renderer2) {
    let my_elem = document.getElementById('test-item');
    this.item = this.el;
    this.el.nativeElement.style.backgroundColor = 'yellow';
    const child = document.createElement('div');
  }
}
