import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { PaymentOptionEnum } from "src/app/checkout/models/checkout.model";
import { LookUpService } from "src/app/services/lookup.service";
import { StorePaymentOptions } from "src/app/stores/models/stores.model";
import { FormFieldPage } from "./form-field.page";


@Component({
  templateUrl:'../views/payment-options.page.html',
  selector : 'app-payment-options',
  styleUrls:['../styles/payment-options.page.scss']
})
export class PaymentOptionsPage implements OnInit, OnDestroy {
  unsubscribe: Subscription[] = [];
  @Input() selectedOption?: StorePaymentOptions;
  @Input() paymentOptions: StorePaymentOptions[] = [];
  paymentMethodOption = PaymentOptionEnum;

  constructor(
    private modalCtrl: ModalController,
    public lookUpService: LookUpService
    ){}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  selectPaymentOption(paymentMethod: StorePaymentOptions) {
    if (paymentMethod.paymentOptionId===PaymentOptionEnum.Account) return this.enterAccount(paymentMethod);
    return this.selectedOption = paymentMethod;
  }

  submit() {
    this.modalCtrl.dismiss(this.selectedOption, 'confirm');
  }


  async enterAccount(paymentMethod: StorePaymentOptions) {
    let name = 'Account number';
    let id = '0'
    const modal = await this.modalCtrl.create({
        component: FormFieldPage,
        cssClass: 'alert-box',
        componentProps: {
            title: 'Enter your Account number to proceed',
            inputFields: [
                {
                  id,
                  name,
                  isRequired: true,
                  type: 'text'
                }
            ]
        }
    });
    await modal.present();
    const { role, data} = await modal.onDidDismiss();
    if (role !=='confirm') return;
    paymentMethod.paymentOption = data[id];
    this.selectedOption = paymentMethod;
  }

}
