<ion-content>
  <ion-grid>
      <ion-button fill="clear" class="close-btn" (click)="onClose()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
      <ion-row class="base-row ion-align-items-center">
          <ion-col>
              <ion-row class="alert-row ion-padding-horizontal">
                  <ion-col class="ion-text-center">
                      <ion-icon id="more" class="alert-icon" [src]="'assets/icon/'+alerts[type].icon+'.svg'" [color]="'assets/icon/'+alerts[type].color"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row class="alert-row ion-padding-horizontal">
                  <ion-col class="ion-text-center">
                      <ion-title>{{alerts[type].title}}</ion-title>
                  </ion-col>
              </ion-row>
              <ion-row class="alert-row ion-padding-horizontal">
                  <ion-col class="ion-text-center">
                      <ion-text class="text" [innerHtml]="text"></ion-text>
                  </ion-col>
              </ion-row>
              <ion-row class="alert-row ion-padding">
                  <ion-col class="ion-text-center">
                      <ion-button expand="block" size="medium" shape="round" [color]="'assets/icon/'+alerts[type].color" (click)="onClose()">Ok</ion-button>
                  </ion-col>
              </ion-row>
          </ion-col>
      </ion-row>
  </ion-grid>
</ion-content>
