import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  BusinessType,
  Country,
  ServiceFields,
  UnitOfMeasurement,
  LocationsWithCities,
} from '../Shared/models/lookup.model';
import { ApiService } from '../Shared/Services/Api.service';
import { StoreType, TagModel } from '../stores/models/stores.model';
import { PaymentOption } from '../stores/models/orders.model';

@Injectable({
  providedIn: 'root',
})
export class LookUpService extends ApiService {
  baseUrl = `${environment.shopUrl}`;
  lookupUrl = `${environment.lookupUrl}`;
  countryUrl = `${environment.countryUrl}`;
  private _services = new BehaviorSubject<ServiceFields[]>([]);
  private _businessTypes = new BehaviorSubject<BusinessType[]>([]);
  private _countries = new BehaviorSubject<Country[]>([]);
  private _storeType = new BehaviorSubject<StoreType[]>([]);
  private _unitsOfMeasurement = new BehaviorSubject<UnitOfMeasurement[]>([]);
  private _locationsWithCities = new BehaviorSubject<LocationsWithCities[]>([]);
  private _paymentOptions = new BehaviorSubject<PaymentOption[]>([]);

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  private fetchServices() {
    return this.GetAll<ServiceFields[]>(
      `${this.lookupUrl}/get-all-services`
    ).pipe(
      map((resData) => {
        return resData.payload;
      }),
      tap((services) => {
        this._services.next(services);
      })
    );
  }

  private fetchBusinessTypes() {
    return this.GetAll<BusinessType[]>(
      `${this.lookupUrl}/get-business-types`
    ).pipe(
      map((resData) => {
        return resData.payload;
      }),
      tap((businessTypes) => {
        this._businessTypes.next(businessTypes);
      })
    );
  }

  getCountries() {
    return this._countries.pipe(
      take(1),
      switchMap((countries) => {
        if (!countries || countries.length <= 0) {
          return this.fetchCountries();
        } else {
          return of(countries);
        }
      })
    );
  }

  private fetchStoreType() {
    return this.GetAll<StoreType[]>(
      `${this.baseUrl}/LookUp/get-store-types`
    ).pipe(
      map((resData) => {
        return resData.payload;
      }),
      tap((storeType) => {
        this._storeType.next(storeType);
      })
    );
  }
  getTags() {
    return this.GetAll<TagModel[]>(`${this.baseUrl}/LookUp/get-tags`).pipe(
      map((resData) => {
        return resData.payload;
      })
    );
  }

  getStoreType() {
    return this._storeType.pipe(
      take(1),
      switchMap((storeType) => {
        if (!storeType || storeType.length <= 0) {
          return this.fetchStoreType();
        } else {
          return of(storeType);
        }
      })
    );
  }

  private fetchLocationsWithCities() {
    return this.GetAll<LocationsWithCities[]>(
      `${this.baseUrl}/LookUp/get-location-with-cities`
    ).pipe(
      map((resData) => {
        return resData.payload;
      }),
      tap((locations) => {
        this._locationsWithCities.next(locations);
      })
    );
  }

  getLocationsWithCities() {
    return this._locationsWithCities.pipe(
      take(1),
      switchMap((locations) => {
        if (!locations || locations.length <= 0) {
          return this.fetchLocationsWithCities();
        } else {
          return of(locations);
        }
      })
    );
  }

  private fetchUnitsOfMeasurement() {
    return this.GetAll<UnitOfMeasurement[]>(
      `${this.baseUrl}/LookUp/get-unit-of-measurements`
    ).pipe(
      map((resData) => {
        return resData.payload;
      }),
      tap((unitsOfMeasurement) => {
        this._unitsOfMeasurement.next(unitsOfMeasurement);
      })
    );
  }

  getUnitsOfMeasurement() {
    return this._unitsOfMeasurement.pipe(
      take(1),
      switchMap((unitsOfMeasurement) => {
        if (!unitsOfMeasurement || unitsOfMeasurement.length <= 0) {
          return this.fetchUnitsOfMeasurement();
        } else {
          return of(unitsOfMeasurement);
        }
      })
    );
  }

  private fetchCountries() {
    return this.GetAll<Country[]>(`${this.countryUrl}`).pipe(
      map((resData) => {
        return resData.payload;
      }),
      tap((countries) => {
        this._countries.next(countries);
      })
    );
  }

  private fetchPaymentOptions() {
    return this.GetAll<PaymentOption[]>(
      `${this.baseUrl}/LookUp/get-payment-options`
    ).pipe(
      map((resData) => {
        return resData.payload;
      }),
      tap((paymentOptions) => {
        this._paymentOptions.next(paymentOptions);
      })
    );
  }

  getPaymentOptions() {
    return this._paymentOptions.pipe(
      take(1),
      switchMap((paymentOptions) => {
        if (!paymentOptions || paymentOptions.length <= 0) {
          return this.fetchPaymentOptions();
        } else {
          return of(paymentOptions);
        }
      })
    );
  }

  getServices(refresh = false) {
    let value = this._services.value;
    if (value && value.length > 0 && !refresh) return of(value);
    return this.fetchServices();
  }

  getBusinessTypes(refresh = false) {
    let value = this._businessTypes.value;
    if (value && value.length > 0 && !refresh) return of(value);
    return this.fetchBusinessTypes();
  }
}
