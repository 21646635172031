import { ProductDataType } from 'src/app/products/models/products.model';
import { v4 as uuidV4 } from 'uuid';

export interface BasketItem {
  productId: number;
  productName: string;
  price: number;
  oldPrice: number;
  quantity: number;
  pictureUrl: string;
  cartOption: CartOptions[];
  priceChanged?: boolean;
}

export interface Cart {
  storeId: string;
  storeName: string;
  storeImage: string;
  items: BasketItem[];
}

export interface Basket {
  userId: string;
  email:string;
  oldBasketId: string;
  cart: Cart[];
}

export class Basket implements Basket {
  constructor(private id?: string) {}
  userId = this.id || uuidV4();
  cart: Cart[] = [];
}

export interface CartOptions {
  id: number;
  name: string;
  variations: CartVariations[];
  isRequired: boolean;
  productOptionType: ProductDataType;
}

export interface CartVariations {
  id: number;
  name: string;
  price: number;
  oldPrice: number;
  quantity: number;
  priceChanged?: boolean;
}

export interface BasketTotals {
  shipping: number;
  subtotal: number;
  total: number;
}
