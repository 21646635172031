<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center ion-padding">

        <ion-title *ngIf="title" class="ion-no-padding ion-margin-bottom">{{title}}</ion-title>
        <div *ngIf="text" class="ion-margin-bottom mt-sm">
          <ion-label>{{text}}</ion-label>
        </div>
        <ion-button (click)="onClose()" class="ion-text-capitalize" [fill]="buttonFill" shape="round">{{buttonText || 'Got It'}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
