import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Order } from 'src/app/stores/models/orders.model';
import { AddRating } from '../models/rating.model';

@Component({
  selector: 'app-rating-feedback',
  templateUrl: '../views/rating-feedback.page.html',
  styleUrls: ['../styles/rating-feedback.page.scss'],
})
export class RatingFeedbackPage implements OnInit, OnDestroy {
  unsubscribe: Subscription[] = [];
  @Input() order!: Order;
  @Input() storeRating?: AddRating;
  form?: FormGroup;
  @Output() next = new EventEmitter<boolean>();
  @Input() updateStoreRating!: (
    part: Partial<AddRating>,
    isFormValid: boolean
  ) => void;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      feedback: [this.storeRating?.description, Validators.required]
    });
    const formSb = this.form?.get('feedback')?.valueChanges.subscribe({
      next: val=> {
        this.updateStoreRating({description: val}, !!this.form?.get('feedback')?.valid)
      }
    });
    if (formSb) this.unsubscribe.push(formSb);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  submit() {
    this.next.emit();
  }

}
