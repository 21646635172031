import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { TextInputPage } from './components/text-input.page';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { NotificationPage } from './components/notification.page';
import { AdvertPage } from './components/advert.page';
import { TransactionStatusPage } from './components/transaction-status.page';
import { PopMenuComponent } from './components/pop-menu.page';
import { DragScrollModule } from 'ngx-drag-scroll';
import { AlertPage } from './components/alert.page';
import { HeaderPage } from './components/header.page';
import { EncodePipe } from './pipes/encode.pipe';
import { KeysPipe } from './pipes/enum.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SwipeItemDirective } from './directives/swipe-item.directive';
import { SwipeItemComponent } from './components/swipe-item.component';
import { BasketItemComponent } from './components/basket-item.component';
import { AddNotesComponent } from './components/add-notes.component';
import { SelectScheduleComponent } from './components/select-schedule.component';
import { PaymentOptionsPage } from './components/payment-options.page';
import { CountdownModule } from 'ngx-countdown';
import { ChallengePage } from './components/Challenge.page';
import { InfoItemPage } from './components/info-item.page';
import { PopAlertComponent } from './components/pop-alert.component';
import { FormFieldPage } from './components/form-field.page';
import { OrderStatusPage } from './components/order-status.page';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { TransferInfoPage } from './components/transfer-info.page';
import { StoreClosedComponent } from './components/store-closed.component';
import { ProductClosedComponent } from './components/product-closed.component';
import { StepperPage } from './components/stepper.page';
import { IosToolBarComponent } from './components/ios-toolbar-component';
import { CategoryStoresComponent } from './components/category-stores.component';
import { PopularItemsComponent } from './components/popular-items.component';
import { EnumToArrayPipe } from './pipes/enumToArray.pipe';
import { PromotionsPage } from './components/promotions.page';
import { NoLocationItemsComponent } from './components/no-location-items.component';
import { RatingAlertComponent } from './components/rating-alert.component';
import { DirectionsMapDirective } from './directives/direcrions.map.directive';
import { WhatsNewPage } from './components/whats-new.page';

@NgModule({
  declarations: [
    TextInputPage,
    NotificationPage,
    AdvertPage,
    AlertPage,
    ChallengePage,
    TransactionStatusPage,
    HeaderPage,
    EncodePipe,
    KeysPipe,
    EnumToArrayPipe,
    SortPipe,
    SelectScheduleComponent,
    SwipeItemDirective,
    FormFieldPage,
    AddNotesComponent,
    SwipeItemComponent,
    BasketItemComponent,
    PaymentOptionsPage,
    OrderStatusPage,
    InfoItemPage,
    PopAlertComponent,
    PopMenuComponent,
    TransferInfoPage,
    StoreClosedComponent,
    StepperPage,
    IosToolBarComponent,
    CategoryStoresComponent,
    PopularItemsComponent,
    ProductClosedComponent,
    PromotionsPage,
    NoLocationItemsComponent,
    RatingAlertComponent,
    DirectionsMapDirective,
    WhatsNewPage
  ],
  imports: [
    RouterModule,
    IonicModule,
    FormsModule,
    DragScrollModule,
    NgOtpInputModule,
    CountdownModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    CommonModule,
    CdkStepperModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleKey,
      libraries: [
        'places',
        'geocoding',
        'maps',
        'streetView',
        'drawing',
        'routes',
        'marker',
      ],
    }),
  ],
  exports: [
    RouterModule,
    IonicModule,
    FormsModule,
    AgmCoreModule,
    DragScrollModule,
    NgOtpInputModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    CountdownModule,
    SwipeItemDirective,
    SwipeItemComponent,
    BasketItemComponent,
    CommonModule,
    TextInputPage,
    InfoItemPage,
    OrderStatusPage,
    PopAlertComponent,
    AddNotesComponent,
    SelectScheduleComponent,
    PaymentOptionsPage,
    ChallengePage,
    NotificationPage,
    AdvertPage,
    ReactiveFormsModule,
    TransactionStatusPage,
    PopMenuComponent,
    EncodePipe,
    FormFieldPage,
    KeysPipe,
    EnumToArrayPipe,
    SortPipe,
    EncodePipe,
    SortPipe,
    AlertPage,
    HeaderPage,
    TransferInfoPage,
    StoreClosedComponent,
    StepperPage,
    CdkStepperModule,
    IosToolBarComponent,
    CategoryStoresComponent,
    PopularItemsComponent,
    ProductClosedComponent,
    PromotionsPage,
    NoLocationItemsComponent,
    RatingAlertComponent,
    DirectionsMapDirective,
    WhatsNewPage
  ],
})
export class SharedModule {}
