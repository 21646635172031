<div class="text-input-wrapper">
  <mat-form-field
    appearance="outline"
    style="width: 100%;"
    [ngClass]= "(controlDir && controlDir.control &&
    controlDir.control.touched)? !controlDir.control.valid ?
    'mat-form-field-invalid' :
    'mat-form-field-valid' : null">
    <mat-label class="label">{{displayText || label}}</mat-label>
    <input
      matInput
      [disabled]="disabled"
      [type]="passwordInput? (showPassword ? 'text' : 'password'): type"
      (input)="onChange($event.target)"
      (blur)="onTouched()"
      id="{{label}}"
      class="text-input"
      [required]="isRequired"
      [readonly]="isReadOnly"
      [formControl]="control"
      [errorStateMatcher]="errorMatcher"
      #input>

      <mat-icon matSuffix (click)="togglePassword()" *ngIf="passwordInput">
        <ion-icon
         name="eye-outline"
         *ngIf="showPassword"
         class="icon">
       </ion-icon>
         <ion-icon
           name="eye-off-outline"
           *ngIf="!showPassword"
           class="icon">
         </ion-icon>
     </mat-icon>

     <mat-icon matSuffix *ngIf="!passwordInput">
      <ion-icon
        matSuffix
        name="alert-circle-outline"
        class="invalid"
        *ngIf="(controlDir && controlDir.control && !controlDir.control.valid &&
        controlDir.control.touched)">
      </ion-icon>
      <ion-icon
        matSuffix
        name="checkmark-circle-sharp"
        class="valid"
        *ngIf="(controlDir && controlDir.control && controlDir.control.valid &&
        controlDir.control.touched)">
      </ion-icon>
     </mat-icon>
    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
      <mat-error *ngIf="controlDir?.control?.errors?.['required']">
        {{label}} is required
      </mat-error>
      <mat-error *ngIf="controlDir?.control?.errors?.['invalidAccountBalance']">
      Insufficient Balance
      </mat-error>
      <mat-error *ngIf="controlDir?.control?.errors?.['invalidAmount']">
        Amount Must be greater than Zero
      </mat-error>
      <mat-error *ngIf="controlDir?.control?.errors?.['pattern']">
        Invalid {{label}}
      </mat-error>
      <mat-error *ngIf="controlDir?.control?.errors?.['minlength']">
        {{label}} must be at least  {{controlDir.control?.errors?.['minlength.requiredLength']}} characters
      </mat-error>
      <mat-error *ngIf="controlDir?.control?.errors?.['min']">
        {{label}} must be at least {{controlDir.control?.errors?.['min.min']| currency:'&#8358;' : 'symbol' : '1.0-0'}}
      </mat-error>
      <mat-error *ngIf="controlDir?.control?.errors?.['max']">
        {{label}} must not be nore than {{controlDir.control?.errors?.['max.max']| currency:'&#8358;' : 'symbol' : '1.0-0'}}
      </mat-error>
      <mat-error *ngIf="controlDir?.control?.errors?.['maxlength']">
        {{label}} must be at most {{controlDir.control?.errors?.['maxlength.requiredLength']}}
      </mat-error>
      <mat-error *ngIf="controlDir?.control?.errors?.['checkPassword']">
        Passwords do not match
      </mat-error>
  </mat-form-field>
</div>
