<div class="swipe-box" id="swipe-box">
  <div class="wrapper" #wrapper>
    <div class="column">
      <ion-icon [name]="leftIcon" color="light" class="ion-margin-start" #trash></ion-icon>
    </div>
    <div class="column" class="ion-text-right">
      <ion-icon [name]="rightIcon" color="light" class="ion-margin-end" #archive></ion-icon>
    </div>
  </div>
  <ng-container [ngTemplateOutlet]="template" #dymanicTemplateRef></ng-container>
</div>
