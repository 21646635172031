import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription, tap } from "rxjs";
import { environment } from "src/environments/environment";
// import { Basket } from "../models/basket";
import { Product } from "../products/models/products.model";
import { ApiService } from "../Shared/Services/Api.service";
import { Store } from "../stores/models/stores.model";
import { Basket, BasketItem, BasketTotals, Cart, CartOptions, CartVariations } from "./models/basket.model";
import { AuthenticationService } from "../auth/services/authentication.service";
import { IdentityAuthModel } from "../auth/models/auth.model";
import { log } from "console";

@Injectable({
  providedIn: 'root'
})
export class BasketService extends ApiService implements OnDestroy {
  baseUrl = environment.cartUrl;
  private basketSource = new BehaviorSubject<Basket | null>(null);
  basketSource$ = this.basketSource.asObservable();
  // private basketTotalSource = new BehaviorSubject<BasketTotals | null>(null);
  // basketTotalSource$ = this.basketTotalSource.asObservable();
  // private basketItemsCount = new BehaviorSubject<number | null>(null);
  // basketItemsCount$ = this.basketItemsCount.asObservable();
  defaultStoreImage = 'assets/images/phone.jpg';
  defaultproductImage = 'assets/images/phone.jpg';
  user?: IdentityAuthModel;
  unsubscribe: Subscription[] = [];

  constructor(
    httpClient: HttpClient,
    private auth: AuthenticationService) {
    super(httpClient);
    this.initUser();
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  getBasket(id: string) {
    return this.GetDataWithFilter<Basket>({id}, `${this.baseUrl}/Cart/get`).subscribe({
      next: res=> {
        this.basketSource.next(res.payload);
        // this.countItems();
        return this.calculateTotals();
      }
    });
  }

  setBasket(basket: Basket) {
    return this.post(basket, `${this.baseUrl}/Cart/update`).pipe(tap(res=> {
      this.basketSource.next(basket);
      // this.countItems();
      this.calculateTotals();
    }));
  }

  getCurrentBasketValue() {
    return this.basketSource.value;
  }

  addItemToBasket(store: Store, item: Product | BasketItem, quantity=1, cartOptions?: CartOptions[]) {
    if (this.isProduct(item)) item = this.mapProductToBasketItem(item);
    if (cartOptions) item.cartOption = cartOptions;
    const basket = this.getCurrentBasketValue() ?? this.createBasket();
    let storeIndex = basket.cart.findIndex(x=> x.storeId===store.storeId);
    if (!(storeIndex>=0)) {
      storeIndex = basket.cart.length;
      basket.cart.push(
        {
          storeId: store.storeId,
          storeImage: store.mediaAsset?.logoUrl || '',
          storeName: store.name,
          items: []
        }
      );
    }
    let itemIndex = -1;
    if (!item.cartOption.length) itemIndex = basket.cart[storeIndex].items.findIndex(x=>!x.cartOption.length && x.productId===(item as BasketItem)?.productId);

    basket.cart[storeIndex].items = this.addOrUpdateItem(basket.cart[storeIndex].items, { ...item }, quantity, itemIndex);
    this.setBasket(basket).subscribe();
  }

  incrementItemQuantity(store: Store, itemIndex: number, quantity=1, duplicate?: boolean) {
    const basket = this.getCurrentBasketValue() ?? this.createBasket();
    let storeIndex = basket.cart.findIndex(x=> x.storeId===store.storeId);
    if (!(storeIndex>=0)) {
      storeIndex = basket.cart.length;
      basket.cart.push(
        {
          storeId: store.storeId,
          storeImage: store.mediaAsset.logoUrl,
          storeName: store.name,
          items: []
        }
      );
    }
    let item = { ...basket.cart[storeIndex].items[itemIndex] };
    item.quantity = quantity;
    basket.cart[storeIndex].items = this.addOrUpdateItem(basket.cart[storeIndex].items, { ...item }, quantity, itemIndex, duplicate);
    this.setBasket(basket).subscribe();
  }

  updateEdittedItem(store: Store, item: BasketItem, itemIndex: number) {
    const basket = this.getCurrentBasketValue() ?? this.createBasket();
    let storeIndex = basket.cart.findIndex(x=> x.storeId===store.storeId);
    if (!(storeIndex>=0)) {
      storeIndex = basket.cart.length;
      basket.cart.push(
        {
          storeId: store.storeId,
          storeImage: store.mediaAsset?.logoUrl,
          storeName: store.name,
          items: []
        }
      );
    }
    basket.cart[storeIndex].items[itemIndex] = item;
    this.setBasket(basket).subscribe();
  }

  removeItemFromBasket(storeId: string, itemIndex: number, quantity=1) {
    const basket = this.getCurrentBasketValue();
    if (!basket) return this.deleteBasket();
    let storeIndex = basket.cart.findIndex(x=> x.storeId===storeId);
    let store = basket.cart[storeIndex];
    if (!store) return this.setBasket(basket);

    const item = store.items[itemIndex];
    if (item) {
      item.quantity -= quantity;
      if (item.quantity<1) {
        store.items = store.items.filter((x, i)=> i!==itemIndex);
      }
      basket.cart[storeIndex] = store;
      if (store.items.length > 0) {
        return this.setBasket(basket);
      }
      else return this.deleteStoreCart(storeId);
    } else {
      return this.setBasket(basket);
    }
  }

  deleteStoreCart(storeId: string) {
    const basket = this.getCurrentBasketValue();
    if (!basket) return this.deleteBasket();
    basket.cart = basket.cart.filter(x=> x.storeId !== storeId);
    if (basket.cart.length > 0) {
      return this.setBasket(basket);
    }
    else return this.deleteBasket();
  }

  deleteBasket() {
    return this.PostDataWithFilter({ userId: this.basketSource.value?.userId }, `${this.baseUrl}/Cart/delete`).pipe(tap(res=> {
      this.basketSource.next(null);
      // this.basketTotalSource.next(null);
      // this.basketItemsCount.next(null);
      localStorage.removeItem('basket_id');
      // this.countItems();
      this.calculateTotals();
    }));
  }

  private addOrUpdateItem(items: BasketItem[], itemToAdd: BasketItem, quantity: number, index=-1, duplicate?: boolean): BasketItem[] {
    if (index >= 0 ) {
      let item = items[index];
      if (duplicate) items.push(itemToAdd);
      else {
        item.quantity+=quantity;
      }
    }
    else {
      items.push(itemToAdd);
    }
    return items;
  }

  createBasket() {
    const basket = new Basket(this.user?.id);
    localStorage.setItem('basket_id', basket.userId);
    return basket;
  }

  initUser() {
    const authSub = this.auth.currentUser$.subscribe(user=> {
      this.user = user;
    });
    this.unsubscribe.push(authSub);
  }

  mapProductToBasketItem(item: Product, quantity = 0): BasketItem {
    return {
      productId: item.id,
      cartOption: [],
      oldPrice: 0,
      priceChanged: false,
      pictureUrl: item.mediaAssets.sort((a, b) => {
        if (a.isDefault > b.isDefault) {
          return 1;
        } else if (a.isDefault < b.isDefault) {
          return  -1;
        } else {
          return 0;
        }
      })[0]?.url || '',
      price: item.price,
      productName: item.name,
      quantity
    }
  }

  getItemsCount(cart: Cart): number {
    return cart.items.reduce((sum, item)=> sum + item.quantity, 0);
    // return cart.items.reduce((sum, item)=> sum + item.quantity + this.countOptionsItems(item.cartOption), 0);
  }


  // private countItems() {
  //   const basket = this.getCurrentBasketValue();
  //   if (!basket) return;
  //   const count = basket.cart.reduce((a, b)=> this.countStoreItems(b.items) + a, 0);
  //   this.basketItemsCount.next(count);
  // }

  countStoreItems(items: BasketItem[]) {
    return items.reduce((a, b)=> b.quantity + a, 0);
  }

  get itemsCount() {
    const basket = this.getCurrentBasketValue();
    if (!basket) return 0;
    return basket.cart.reduce((a, b)=> this.countStoreItems(b.items) + a, 0);
  }

  private countOptionsItems(options: CartOptions[]) {
    return options.reduce((a, b)=> this.countVariationsItems(b.variations) + a, 0);
  }

  private countVariationsItems(variations: CartVariations[]) {
    return variations.reduce((a, b)=> b.quantity + a, 0);
  }

  private calculateTotals() {
    const basket = this.getCurrentBasketValue();
    if (!basket) return;
    const shipping = 0;
    const subtotal = basket.cart.reduce((a, b)=> this.calculateCartTotals(b.items) + a, 0);
    const total = subtotal + shipping;
    // this.basketTotalSource.next({shipping, total, subtotal})
  }

  private isProduct(item: Product | BasketItem): item is Product {
    return (item as Product).name !== undefined;
  }

  calculateCartTotals(items: BasketItem[]) {
    return items.reduce((a, b)=> (b.price * b.quantity) + this.calculateOptionsTotals(b.cartOption) + a, 0);
  }

  calculateItemTotal(item: BasketItem) {
    return (item.price * item.quantity) + this.calculateOptionsTotals(item.cartOption)
  }

  private calculateOptionsTotals(options: CartOptions[]) {
    return options.reduce((a, b)=> this.calculateVariationTotals(b.variations) + a, 0);
  }

  private calculateVariationTotals(variations: CartVariations[]) {
    return variations.reduce((a, b)=> (b.price * b.quantity) + a, 0);
  }
}
