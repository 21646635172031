import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from 'src/app/stores/models/orders.model';
import { AddRating, FeedBack, FeedBackTypeEnum, Rating, ReviewTypeEnum } from '../models/rating.model';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DeliveryOptionsEnum } from 'src/app/checkout/models/checkout.model';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-rate-products',
  templateUrl: '../views/rate-products.page.html',
  styleUrls: ['../styles/rate-products.page.scss'],
})
export class RateProductsPage implements OnInit {
  form!: FormGroup;
  @Input() order!: Order;
  @Output() next = new EventEmitter<boolean>();
  @Output() complete = new EventEmitter<boolean>();
  formChanged = false;
  feedbackPositive: FeedBack[] = [];
  feedbackNegative: FeedBack[] = [];
  private unsubscribe: Subscription[] = [];
  @Input() updateProductsRating!: (
    part: AddRating[],
    isFormValid: boolean
  ) => void;

  constructor(
    private fb: FormBuilder,
    private orderService: OrderService
    ) {}

  ngOnInit() {
    this.initFeedBack();
    let productControls: FormGroup[] = this.order.orderItems.map( product=> {
      return this.fb.group({
        id: [''+product.id],
        like: [undefined],
        feedback: ['']
      });
    })
    this.form = this.fb.group({
      products: this.fb.array(productControls)
    });

    const formSb = this.form.valueChanges.subscribe((val)=> {
      this.formChanged = true;
      this.updateProduct((val?.products as any[]).filter(product=>(product.like!==undefined && product.like!==null)), this.form?.valid);
    });
    if (formSb) this.unsubscribe.push(formSb);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  initFeedBack() {
    const fbNegSb = this.orderService.getFeedBackByFeedBack(ReviewTypeEnum.Product, FeedBackTypeEnum.Bad).subscribe({
      next: res=> {
        if (!res) return;
        this.feedbackNegative = res;
      }
    });
    this.unsubscribe.push(fbNegSb);

    const fbPosSb = this.orderService.getFeedBackByFeedBack(ReviewTypeEnum.Product, FeedBackTypeEnum.Good).subscribe({
      next: res=> {
        if (!res) return;
        this.feedbackPositive = res;

      }
    });
    this.unsubscribe.push(fbPosSb);
  }

  updateProduct(products: {like: boolean, feedback: FeedBack, id: string}[], isValid: boolean) {
    let ratings: AddRating[] = products.map(product=> {
      return {
        rating: product.like ? 5: 1,
        description: product.feedback?.name,
        feedBackTypeId: product.feedback?.id,
        reviewType: ReviewTypeEnum.Product,
        reviewTypeId: product.id
      }
    });
    this.updateProductsRating(ratings, isValid);
  }

  submit() {
    if (this.order.deliveryMethod.id===DeliveryOptionsEnum.PickUp) return this.complete.emit();
    return this.next.emit(true);
  }

  getProductControl(i: number) {
    return (this.form?.get('products') as FormArray).at(i) as FormGroup;
  }

  rateProduct(i: number, rating: boolean) {
    this.getProductControl(i).get('like')?.setValue(this.getProductControl(i).get('like')?.value===rating ? undefined: rating)
  }

  productFeedback(i: number, feedback: FeedBack) {
    this.getProductControl(i).get('feedback')?.setValue(this.getProductControl(i).get('feedback')?.value?.id===feedback.id ? undefined: feedback)
  }

}
