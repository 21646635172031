<ion-content>
  <ion-grid *ngIf="form" [formGroup]="form" class="ion-margin-horizontal h-100 d-flex flex-column ion-align-items-stretch ion-justify-content-between">
    <div>
      <ion-row class="ion-margin-top">
        <ion-col class="ion-text-center">
          <ion-thumbnail class="d-inline-block">
            <img alt="Silhouette of a person's head" class="h-100 w-100" src="assets/images/comment.png" />
          </ion-thumbnail>
        </ion-col>
      </ion-row>

      <ion-row class="mt-sm">
        <ion-col class="ion-text-center">
          <ion-label class="ion-no-padding title">Share why you love {{order.store.storeName}} with others</ion-label>
        </ion-col>
      </ion-row>

      <ion-row class="mt-sm">
        <ion-col class="ion-text-start">
          <ion-textarea class="feedback-box" fill="outline" placeholder="Type something here" [autoGrow]="true"
           formControlName="feedback">
          </ion-textarea>
        </ion-col>
      </ion-row>


      <ion-row class="mt-sm">
        <ion-col class="ion-text-start">
          <ion-card-subtitle class="ion-text-justify">By clicking Continue. I agree that Noddit may publicly share this review along with my name and order history, for promotional purposes. This review is true and reflects my experience with this merchant.</ion-card-subtitle>
        </ion-col>
      </ion-row>
    </div>
    <div>
      <ion-button class="ion-margin-vertical ion-text-capitalize btn-md" (click)="submit()" [disabled]="form.invalid" shape="round" expand="block">Continue</ion-button>
    </div>
  </ion-grid>
</ion-content>
