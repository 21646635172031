import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from 'src/app/stores/models/orders.model';
import { AddRating, Rating } from '../models/rating.model';

@Component({
  selector: 'app-rate-store',
  templateUrl: '../views/rate-store.page.html',
  styleUrls: ['../styles/rate-store.page.scss'],
})
export class RateStorePage implements OnInit {
  @Input() order!: Order;
  @Input() currentRating: number | undefined = 4;
  @Output() next = new EventEmitter<boolean>();
  @Input() updateStoreRating!: (
    part: Partial<AddRating>,
    isFormValid: boolean
  ) => void;

  ratings: {id: number}[] = [
    {
      id: 1,
      // description: 'Excellent ratio what did you enjoy?',
      // options: [
      //   'Creative Options', 'Sustainable Packaging', 'Customisable portion', 'Delicious'
      // ]
    },
    {
      id: 2,
      // description: 'Excellent ratio what did you enjoy?',
      // options: [
      //   'Creative Options', 'Sustainable Packaging', 'Customisable portion', 'Delicious'
      // ]
    },
    {
      id: 3,
      // description: 'Excelent ratio what did you enjoy?',
      // options: [
      //   'Creative Options', 'Sustainable Packaging', 'Customisable portion', 'Delicious'
      // ]
    },
    {
      id: 4,
      // description: 'Excelent ratio what did you enjoy?',
      // options: [
      //   'Creative Options', 'Sustainable Packaging', 'Customisable portion', 'Delicious'
      // ]
    },
    {
      id: 5,
      // description: 'Excelent ratio what did you enjoy?',
      // options: [
      //   'Creative Options', 'Sustainable', 'Customisable portion', 'Delicious'
      // ]
    }
  ]
  constructor() {}

  ngOnInit() {
  }

  setRating(rating: number) {
    if (this.currentRating === rating) this.currentRating = undefined;
    else this.currentRating = rating;
    return this.updateStoreRating({rating}, true);
  }

  submit() {
    this.next.emit(true);
  }

  // addFeedback(feedback: string) {
  //   if (this.currentRating?.options.some(x=>x===feedback)) return this.currentRating.options = this.currentRating.options.filter(x=>x!==feedback)
  //   return this.currentRating?.options.push(feedback)
  // }

  // get currentOptions() {
  //   return this.ratings.find(x=>x.id===this.currentRating?.id)?.options ?? []
  // }

  // containsFeedback(feedback: string) {
  //   return !!this.currentRating?.options.some(x=>x===feedback);
  // }

  update() {
    this.updateStoreRating({
      description: 'Testing Description',
    },true);
  }

}
