<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="previous()">
        <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="skip()"> Skip </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-slides class="h-100" #slides [options]="{ noSwipingClass: 'swiper-no-swiping' }">
    <ion-slide>
      <app-rate-store
        [updateStoreRating]="updateStoreRating"
        (next)="next()"
        class="h-100 w-100"
        [currentRating]="storeRating$.value.rating"
        [order]="order">
      </app-rate-store>
    </ion-slide>
    <ion-slide>
      <app-rating-feedback
      [updateStoreRating]="updateStoreRating"
      [storeRating]="storeRating$.value"
      (next)="next()" class="h-100 w-100" [order]="order"></app-rating-feedback>
    </ion-slide>
    <ion-slide>
      <app-rate-products
      [updateProductsRating]="updateProductsRating"
      (complete)="submit()" (next)="next()" (complete)="submit()" class="h-100 w-100" [order]="order"></app-rate-products>
    </ion-slide>
    <ion-slide *ngIf="order.deliveryMethod.id === deliveryOptionsEnum.Delivery">
      <app-rate-delivery
      [updateDriverRating]="updateDriverRating"
      (complete)="submit()" class="h-100 w-100" [order]="order"></app-rate-delivery>
    </ion-slide>
  </ion-slides>
</ion-content>
