import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ModalController } from "@ionic/angular";
// import { NativeBiometric } from "capacitor-native-biometric";
import { CountdownEvent } from "ngx-countdown";
import { Subscription } from "rxjs";
// import { DatabaseService } from "src/app/Auth/database.service";
import { Challenge, AuthenticationTypes } from "../models/challenge.model";
import { ChallengeValues } from "../models/challengeValues";
import { AuthChallengeEnum } from "../models/enums";
import { ChallengeService } from "../Services/challenge.service";
// import { IgnoreAppLockService } from "../Services/ignore-lock.service";
// import { NativePlatformService } from "../Services/native/nativePlatform.service";
// import { ToastService } from "../Services/toast.service";


@Component({
templateUrl: '../views/challenge.html',
selector: 'user-challenges',
styleUrls:['../styles/challenges.scss']
})
export class ChallengePage implements OnInit, OnDestroy {
    myFormGroup!: FormGroup;
    challengeType= AuthChallengeEnum;
    @ViewChild('templateRef')templateRef:any
    challengeValues:ChallengeValues[]= [];
    @Input('amount') amount:number=0;
    authenticationType!: AuthenticationTypes;
    requiredChallenges:Challenge[] = [];
    otherChallenges :Challenge[] = [];
    @Input() inputChallenges :Challenge[] = [];
    showOptionalChallenges: boolean= false;
    showOptionalChallengesInput:boolean=false;
    optionalChallenges:Challenge[]=[];
    optionalIndex!:number;
    resendBtn:boolean = true;
    timer!: boolean;
    errorMessage!:string;
    filteredChallenges: AuthenticationTypes[] = [];
    challengeSub?: Subscription;
    isBiometricDevice?: boolean;
    isBiometricEnabled?: boolean;
    biometricEnabledSub?: Subscription;
    biometricDeviceSub?: Subscription;

    constructor(
      public challengeService: ChallengeService,
      // private toastService: ToastService,
      // private dbService: DatabaseService,
      // public nativePlatform: NativePlatformService,
      // private ignoreLock: IgnoreAppLockService,
      public modalCtrl: ModalController){}

    async ngOnInit(): Promise<void> {
      // if (!window.history.state.modal) {
      //   const modalState = { modal: true };
      //   history.pushState(modalState, null);
      // }

      this.challengeSub = this.challengeService.getChallenges().subscribe({
        next: (result) => {
          this.filteredChallenges = [ ...result ]
          let authTypes  =  this.filteredChallenges.find(x=>this.amount>=x.minLimit && this.amount<=x.maxLimit);
          if (authTypes) this.authenticationType = { ...authTypes };

            if (this.inputChallenges.length > 0) {
              if (this.inputChallenges.length>0) {
                this.authenticationType.challenges = this.authenticationType.challenges.filter(x=>
                  this.inputChallenges.find(challenge=>challenge.id === x.id));
                  this.authenticationType.challengeCount = this.inputChallenges.length;
                  this.authenticationType.challenges[0].userMessage = this.inputChallenges[0].userMessage;
              }
            }
              this.requiredChallenges = this.authenticationType.challenges.filter(x=>x.isRequired);
              this.otherChallenges =
              this.authenticationType.challenges.filter(x=>!x.isRequired);

            if(this.requiredChallenges.length<1) {
              if (this.otherChallenges.length === 1) {
                this.optionalChallenges = this.otherChallenges;
                this.showOptionalChallengesInput = true;
              } else {
                this.showOptionalChallenges = true;
              }
            }
        }, error: err=> {
          this.dismiss();
        }
      });


      // this.biometricDeviceSub = (await this.nativePlatform.isBiometricDevice()).subscribe(isBiometricEnabled=> {
      //   this.isBiometricEnabled = isBiometricEnabled;
      // });

      // this.biometricEnabledSub = this.dbService.isBiometricEnabled$.subscribe(isBiometricDevice=> {
      //   this.isBiometricDevice = isBiometricDevice;
      // });
    }

    onInputChanged(e: any, challenge: Challenge){
        if(e.length ===challenge.textLength){
            const challengeValue:ChallengeValues = {
              id:challenge.id,
              value: e,
              isRequired: challenge.isRequired
            };
            let index = this.challengeValues.findIndex(x=>x.id == challenge.id);
            if(index>-1){
              this.challengeValues[index].value = e;
            }
            else{
                this.challengeValues.push(challengeValue);
            }
            if(challenge.isRequired && this.otherChallenges.length > 0){
                if (this.otherChallenges.length === 1) {
                  this.onUserPreferedOptionSelected(0, this.otherChallenges[0]);
                } else {
                  this.showOptionalChallenges = true;
                }
            } else {
              this.validateChallenge();
            }
        }
    }

    async presentModal(data:any) {
        const modal = await this.modalCtrl.create({
          component: data,
          cssClass: 'my-custom-class'
        });
        return await modal.present();
      }

      onUserPreferedOptionSelected(i: number, challenge: Challenge){
        this.optionalIndex = i;
        if(this.authenticationType.challengeCount === this.challengeValues.length){
            let Index = this.challengeValues.findIndex(x=>!x.isRequired);
            this.challengeValues[Index].id = challenge.id;
        }
        this.optionalChallenges = this.otherChallenges.filter(x=>x.id === challenge.id);
       if((challenge.name === this.challengeType.OTP && this.inputChallenges.length<1) || (challenge.name === this.challengeType.OTP && this.authenticationType.challengeCount>1)){

         let Index = this.challengeValues.findIndex(x=>!x.isRequired && x.id == challenge.id);
          if(Index <=-1){
        const challengeValue:ChallengeValues ={
            id:challenge.id,
            value: '',
            isRequired: false
          };
          this.challengeValues.push(challengeValue);
        }
       this.validateChallenge();
       }
       else{
           this.showOptionalChallengesInput = true;
       }
      //  if (challenge.name===AuthChallengeEnum.FingerPrint) this.biometricVerify(challenge);
      }

      async biometricVerify(challenge: Challenge) {
        // if (!this.isBiometricEnabled) return this.toastService.presentToast('TouchID is not available on your device');
        // if (!this.isBiometricEnabled) return this.toastService.presentToast('TouchID is not enabled on your device');
        // this.ignoreLock.ignore(true);
        try {
          // this.ignoreLock.ignore(true);
          // await NativeBiometric.verifyIdentity({
          //   reason: "Authentication",
          //   title: "Prove it's you",
          //   description: "We need to confirm it's you",
          // });
          // this.ignoreLock.ignore(false);
          // const value = true;
          // let index = this.challengeValues.findIndex(x=>x.id == challenge.id);
          // if(index>-1){
          //   this.challengeValues[index].value = value;
          // }
          // else{
          //   const challengeValue:ChallengeValues = {
          //     id:challenge.id,
          //     value,
          //     isRequired: challenge.isRequired
          //   };
          //   this.challengeValues.push(challengeValue);
          // }
          // if(challenge.isRequired && this.otherChallenges.length > 0){
          //     if (this.otherChallenges.length === 1) {
          //       this.onUserPreferedOptionSelected(0, this.otherChallenges[0]);
          //     } else {
          //       this.showOptionalChallenges = true;
          //     }
          // } else {
          //   this.validateChallenge();
          // }
        } catch (error) {
          // this.ignoreLock.ignore(false);
          // if (error?.code==10) return this.biometricVerify(challenge);
          // return this.toastService.presentToast('Authentication failed');
        }
      }

    validateChallenge(){
      if(this.challengeValues.length ===this.authenticationType.challengeCount){
        this.modalCtrl.dismiss(this.challengeValues, 'confirm');
      }
    }

      onResendOtp() {
        this.modalCtrl.dismiss(null, 'resend-otp');
    }
    handleEvent(e:CountdownEvent) {
        if (e.action === 'restart') {
            this.resendBtn = false;
        }
        if (e.action === 'done') {
            this.resendBtn = true;
        }
    }

      dismiss() {
        this.modalCtrl.dismiss();
      }

      ngOnDestroy() {
        this.challengeSub?.unsubscribe();
        this.biometricEnabledSub?.unsubscribe();
      }
}
