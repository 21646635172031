import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { AuthenticationTypes, Challenges } from "../models/challenge.model";
import { environment } from "src/environments/environment";
import { map, switchMap, take, tap } from "rxjs/operators";
import { BehaviorSubject, of } from "rxjs";
import { CountdownComponent } from "ngx-countdown";
import { ApiService } from "./Api.service";
import { ChallengeValues } from "../models/challengeValues";

@Injectable({
  providedIn: "root",
})
export class ChallengeService extends ApiService {
  baseUrl = `${environment.orderUrl}`;
  private challengeSource = new BehaviorSubject<AuthenticationTypes[] | null>(null);
  challenge$ = this.challengeSource.asObservable();
  private userChallengeSource = new BehaviorSubject<AuthenticationTypes | null>(null);
  userChallenge$ = this.userChallengeSource.asObservable();
  countdown!: CountdownComponent;
  startTimer = new EventEmitter<boolean>();

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  // getChannelChallenges() {
  //   return this.GetAll<AuthenticationTypes[]>(
  //     `${this.baseUrl}/get-challenges`
  //   ).pipe(
  //     map((res) => {
  //       this.challengeSource.next(res.payload);
  //       return res.payload;
  //     })
  //   );
  // }

  // getChallengeToUse(amount: number) {
  //   var allChallenges = this.challengeSource.value;
  //   let transactionAuthentication = allChallenges?.find(
  //     (x) => x.minLimit >= amount && amount <= x.maxLimit
  //   );
  //   this.userChallengeSource.next(transactionAuthentication ? transactionAuthentication: null);
  //   return of(transactionAuthentication);
  // }

  private fetchChallenges() {
    return this.GetAll<AuthenticationTypes[]>(
      `${this.baseUrl}/get-challenges`
    ).pipe(
      map((resData) => {
        return resData.payload;
      }),
      tap((challenges) => {
        this.challengeSource.next(challenges);
      })
    );
  }

  getChallenges() {
    return this.challengeSource.pipe(
      take(1),
      switchMap((challenges) => {
        if (!challenges || challenges.length <= 0) {
          return this.fetchChallenges();
        } else {
          return of(challenges);
        }
      })
    );
  }

  formatChallenges(challenges:ChallengeValues[] | Challenges[]) {
    if (this.instanceOfChallenges(challenges)) {
      return challenges;
    } else {
      let formattedChallenges:Challenges[] = [];
      challenges.forEach(challenge=> {
      formattedChallenges.push({challengeType: challenge.id, value: challenge.value})
    });
    return formattedChallenges;
    }
  }

  instanceOfChallenges(object: any): object is Challenges[] {
    return 'challengeType' in object[0];
  }

}
