import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopMenuItem } from '../models/pop-menu.model';

@Component({
  selector: 'pop-menu',
  styleUrls: ['../styles/pop-menu.scss'],
  templateUrl: '../views/pop-menu.html',
})
export class PopMenuComponent implements OnInit {
    @Input() popMenuItems: PopMenuItem[] = [];

    constructor(private popoverCtrl: PopoverController) {}

    ngOnInit(): void { }

    selectItem(item: PopMenuItem) {
        this.popoverCtrl.dismiss(item, 'confirm');
    }

}
