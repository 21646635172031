export enum OrderStatusEnum {
  Received = 1,
  PendingPayment = 2,
  Processing = 3,
  Accepted = 4,
  Shipped = 5,
  Delivered = 6,
  Return = 7,
  Completed = 8,
  Refund = 9,
  PendingLoanApproval = 10,
  Approved = 11,
  Cancelled = 12,
}

export enum PaymentStatusEnum {
  Successful = 1,
  Processing = 2,
  PendingDebit = 3,
  Pending2FA = 4,
  Pending = 5,
  Failed = 6
}
