<ion-header>
  <ion-toolbar [color]="'light'">
      <ion-buttons slot="primary">
          <ion-button [color]="'primary'" size="small" (click)="dismiss()">
              <ion-icon slot="icon-only" class="search-icon" name="close-outline"></ion-icon>
          </ion-button>
      </ion-buttons>
      <ion-title [color]="'primary'" class="ion-text-center">Schedule Delivery</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid class="ion-margin-top">
    <drag-scroll class="ion-padding-horizontal">
      <div drag-scroll-item class="drag-item" *ngFor="let option of [1,2,3,4,5,6,7]; let i= index">

        <ion-list lines="none" class="ion-no-padding">
          <ion-item (click)="selectDate(i)" [ngClass]="{ 'active-date': selectedDate===i }" button="true" class="date-item">
            <div>
              <ion-label class="ion-no-padding mb-sm order-label" [color]="selectedDate===i ? 'primary': 'dark'">Today</ion-label>
              <ion-card-subtitle class="order-subtitle">Mar 2</ion-card-subtitle>
            </div>
          </ion-item>
        </ion-list>
      </div>
    </drag-scroll>

    <ion-list>
      <ion-radio-group [(ngModel)]="selectedTime">
        <ion-item *ngFor="let time of scheduleTimes">
          <ion-label>{{time.value}}</ion-label>
          <ion-radio slot="end" [value]="time.id"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>

  </ion-grid>
</ion-content>
<ion-footer class="ion-no-border ion-padding-bottom">
  <ion-row>
      <ion-col>
          <ion-button [disabled]="!selectedTime || !selectedDate" expand="full" shape="round" class="btn-md ion-text-capitalize" (click)="submit()">Done</ion-button>
      </ion-col>
  </ion-row>
</ion-footer>
