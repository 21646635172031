import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Store, StoresQuery, StoreVisit } from '../stores/models/stores.model';
import { ApiService } from 'src/app/Shared/Services/Api.service';
import {
  Product,
  ProductCategory,
} from 'src/app/products/models/products.model';
import { AddressService } from '../address/address.service';
import { UserAddress } from '../address/models/address.model';

@Injectable({
  providedIn: 'root',
})
export class StoreService extends ApiService {
  baseUrl = `${environment.shopUrl}`;
  private _stores = new BehaviorSubject<Store[]>([]);
  private _address = new BehaviorSubject<UserAddress | null>(null);
  private _visitedStores = new BehaviorSubject<string[]>([]);
  private _categoryStores = new BehaviorSubject<ProductCategory[]>([]);
  private _popularProducts = new BehaviorSubject<Product[]>([]);
  defaultStoreBackground = 'assets/images/shop.jpg';
  defaultStoreLogo = 'assets/images/shop.jpg';
  defaultProductImage = 'assets/images/product.jpg';
  private isStoreActive = new BehaviorSubject<boolean>(true);
  isStoreActive$ = this.isStoreActive.asObservable();
  categoryStores$ = this._categoryStores.asObservable();
  popularProducts$ = this._popularProducts.asObservable();
  productsCache = new Map<string, Product[]>();
  categoriesCache = new Map();
  featureStoreCache = new Map();
  constructor(
    httpClient: HttpClient,
    public addressService: AddressService,
    private zone: NgZone
  ) {
    super(httpClient);
    this.initAddress();
  }

  searchStores(query: StoresQuery) {
    return this.post<Store[]>(query, `${this.baseUrl}/search-stores`);
  }

  initAddress() {
    this.addressService.address$.subscribe((address) => {
      this._address.next(address);
      if (address?.city) this.submitVisits();
    });
  }

  addVisits(visit: string) {
    let visitedStores = this._visitedStores.value;
    this._visitedStores.next([...visitedStores, visit]);
    this.submitVisits();
  }

  submitVisits() {
    let address = this._address.value;
    let visitedStores = this._visitedStores.value;
    if (!address?.city || !visitedStores.length) return;
    let query: StoreVisit[] = visitedStores.map((storeId) => {
      return {
        city: address?.city ?? '',
        country: '',
        region: '',
        ipAddress: '',
        latitude: address?.lat ?? 0,
        longitude: address?.lat ?? 0,
        referrerUrl: '',
        userAgent: '',
        storeId,
      };
    });

    this.zone.run(() => {
      this.post<boolean>(
        {
          visits: query,
        },
        `${this.baseUrl}/add-store-visits`
      ).subscribe({
        next: () => {
          this._visitedStores.next([]);
        },
      });
    });
  }

  getStoreById(storeId: string) {
    let stores = this._stores.value;
    if (!stores.length) return this.fetchStoreById(storeId);
    let store = stores.find((store) => store.storeId === storeId);
    if (store) {
      this.isStoreActive.next(store.isActive);
      return of(store);
    } else return this.fetchStoreById(storeId);
  }

  fetchStoreById(storeId: string) {
    return this.GetAll<Store>(`${this.baseUrl}/${storeId}/get-store`).pipe(
      map((resData) => {
        this._stores.next([...this._stores.value, resData.payload]);
        this.isStoreActive.next(resData.payload.isActive);
        return resData.payload;
      })
    );
  }

  getCategoryStores(city: string) {
    const cacheMap = this.categoriesCache.get(city);
    if (cacheMap) {
      return of(cacheMap as ProductCategory[]);
    }

    return this.GetDataWithFilter<ProductCategory[]>(
      { city },
      `${this.baseUrl}/default-store`
    ).pipe(
      map((resData) => {
        this.categoriesCache.set(city, resData.payload);
        return resData.payload;
      })
    );
  }

  getPopularProducts(city: string, location: string) {
    const cacheKey = `${location}|${city}`;
    const cacheMap = this.productsCache.get(cacheKey);
    if (cacheMap && cacheMap.length > 0) {
      return of(cacheMap as Product[]);
    }
    return this.GetDataWithFilter<Product[]>(
      { city, location },
      `${this.baseUrl}/popular-products`
    ).pipe(
      map((resData) => {
        this.productsCache.set(cacheKey, resData.payload);
        return resData.payload;
      })
    );
  }

  getFeaturedStores(city?: string | null, location?: string | null) {
    const cacheKey = `${location}|${city}`;
    const cacheMap = this.featureStoreCache.get(cacheKey);
    if (cacheMap) {
      return of(cacheMap as Store[]);
    }
    return this.GetDataWithFilter<Store[]>(
      { city, location },
      `${this.baseUrl}/featured-stores`
    ).pipe(
      map((resData) => {
        this.featureStoreCache.set(cacheKey, resData.payload);
        return resData.payload;
      })
    );
  }
  getProducts(storeId: string) {
    return this.GetDataWithFilter<ProductCategory[]>(
      { storeId },
      `${this.baseUrl}/${storeId}/get-products-basic-details`
    ).pipe(
      map((resData) => {
        return resData.payload;
      })
    );
  }

  getProductById(productId: string) {
    return this.GetAll<Product>(
      `${this.baseUrl}/${productId}/get-product`
    ).pipe(
      map((resData) => {
        return resData.payload;
      })
    );
  }

  addAsFavoriteStore(storeId: string, isFavorite: boolean) {
    return this.post<boolean>(
      { storeId, isFavorite },
      `${this.baseUrl}/add-favorite-store`
    );
  }

  getFavoriteStores(long: number, lat: number) {
    return this.post<Store[]>({ long, lat }, `${this.baseUrl}/favorite-stores`);
  }
}
