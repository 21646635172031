import { ApiResponse } from "./../models/ApiResponse";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";

export class ApiService {
  model: any = {};
  apiurl: string;
  constructor(protected httpClient: HttpClient, protected url: string = "") {
    this.apiurl = this.url;
  }
  public post<T>(item: any, url: string): Observable<ApiResponse<T>> {
    return this.httpClient.post<ApiResponse<T>>(`${url}`, item);
  }

  public put<T>(item: any, url: string): Observable<ApiResponse<T>> {
    return this.httpClient.put<ApiResponse<T>>(`${url}`, item);
  }

  public delete<T>(item: any, url: string): Observable<ApiResponse<T>> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: item,
    };
    return this.httpClient.delete<ApiResponse<T>>(`${url}`, options);
  }

  public createBaseUrl<T>(item: T, url: string): Observable<ApiResponse<T>> {
    return this.httpClient.post<ApiResponse<T>>(`${url}`, item);
  }

  public PostDataWithFilter<T>(query: any, url: string, body={}): Observable<ApiResponse<T>> {
    return this.httpClient.post<ApiResponse<T>>(`${url}?${this.toQueryString(query)}`, body);
  }

  update<T>(resource: any, url: any): Observable<ApiResponse<T>> {
    return this.httpClient.put<ApiResponse<T>>(`${url}`, resource);
  }
  Get<T>(id: any, url: any): Observable<ApiResponse<T>> {
    return this.httpClient.get<ApiResponse<T>>(`${url}` + "/" + id);
  }
  GetWithQueryString<T>(input: any, url: any) {
    return this.httpClient.get<ApiResponse<T>>(
      `${url}` + "?" + this.toQueryString(input)
    );
  }
  GetDataWithFilter<T>(query: any, url: any): Observable<ApiResponse<T>> {
    return this.httpClient.get<ApiResponse<T>>(
      `${url}` + "?" + this.toQueryString(query)
    );
  }

  GetAll<T>(url: any): Observable<ApiResponse<T>> {
    return this.httpClient.get<ApiResponse<T>>(`${url}`);
  }
  toQueryString(queryString: any) {
    const parts = [];
    for (const property in queryString) {
      const value = queryString[property];
      if (value !== null && value !== undefined) {
        parts.push(
          encodeURIComponent(property) + "=" + encodeURIComponent(value)
        );
      }
    }
    return parts.join("&");
  }
  downloadFile(url: any, filenameWithExtension: string): void {
    this.httpClient
      .post(
        url,
        {},
        {
          responseType: "blob",
        }
      )
      .subscribe((response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: dataType,
          })
        );
        if (filenameWithExtension)
          downloadLink.setAttribute("download", filenameWithExtension);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }
  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
