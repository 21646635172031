import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/products/models/products.model';
import { DayOfWeek } from '../models/business-hours.model';

@Component({
  template: `
    <ion-row
      *ngIf="product?.availability?.isToday"
      class="closed-info gap-sm ion-justify-content-between ion-align-items-center"
    >
      <ion-label
        *ngIf="product.availability?.openingTime && !isAvailableOnCurrentTime()"
        color="primary"
        class="opening-time font-sm"
      >
        Available Today,
        {{ product.availability?.openingTime | date : 'h:mm a' }} -
        {{ product.availability?.closingTime | date : 'h:mm a' }}
      </ion-label>
    </ion-row>
    <ion-row
      *ngIf="!product?.availability?.isToday"
      class="closed-info gap-sm ion-justify-content-between ion-align-items-center"
    >
      <!-- <ion-label class="v-middle font-sm closed-label" color="light"
      >
      <ion-icon
        class="v-middle"
        color="light"
        name="lock-closed-outline"
      ></ion-icon>Unavailable
      </ion-label
    > -->
      <ion-label
        *ngIf="product.availability?.openingTime"
        color="primary"
        class="opening-time font-sm"
      >
        Available {{ dayOfWeek[product.availability?.day ?? 0] }}
        {{ product.availability?.openingTime | date : 'h:mm a' }}
      </ion-label>
    </ion-row>
  `,
  selector: 'app-product-closed',
  styles: [
    `
      .opening-time {
        font-size: 0.9rem;
        margin-right: 0.2rem;
      }

      .closed-info {
        padding: 0.3rem;
        margin-bottom: 0.5rem;
      }
    `,
  ],
})
export class ProductClosedComponent implements OnInit {
  @Input() product!: Product;
  now: Date = new Date();
  dayOfWeek = DayOfWeek;
  constructor() {
    setInterval(() => {
      this.now = new Date();
    }, 1000);
  }

  ngOnInit(): void {}

  getTime(dateTime: any) {
    return new Date(dateTime).getTime();
  }

  get currentTime() {
    return this.now.getTime();
  }
  isAvailableOnCurrentTime() {
    const openingTime = this.getTime(this.product.availability?.openingTime);
    const closingTime = this.getTime(this.product.availability?.closingTime);
    return this.currentTime >= openingTime && this.currentTime <= closingTime;
  }
}
