import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { FormFieldInput } from "../models/form-field.model";

@Component({
  templateUrl: "../views/form-field.html",
  selector: "app-form-field",
  styleUrls: ["../styles/form-field.scss"],
})
export class FormFieldPage implements OnInit {
  @Input() form!: FormGroup;
  @Input() title!: string;
  @Input() inputFields: FormFieldInput[] = [];

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    let group: any = {};
    this.inputFields.forEach((input) => {
      const validators = [];
      if (input.isRequired) {
        validators.push(Validators.required);
      }
      if (input.minlength) {
        validators.push(Validators.minLength(input.minlength));
      }
      if (input.maxlength) {
        validators.push(Validators.minLength(input.maxlength));
      }
      group[input.id] =
        validators.length > 0
          ? new FormControl("", validators)
          : new FormControl("");
    });
    this.form = new FormGroup(group);
  }

  submit() {
    this.modalCtrl.dismiss(this.form.value, "confirm");
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  onOtpChange(e: any, input: FormFieldInput) {
    this.form?.get(input.name)?.patchValue(e);
    if (e.length == input.otpTextLength) {
      this.modalCtrl.dismiss(this.form.value, "confirm");
    }
  }
}
