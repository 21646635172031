export const environment = {
  production: true,
  appUrl: 'https://shop.getnoddit.com',
  baseUrl: 'https://api.getnoddit.com/merchant',
  cartUrl: 'https://commerce.getnoddit.com/cart-api/api/v1.0',
  countryUrl: 'https://auth.getnoddit.com/api/v1/LookUp/get-countries',
  deliveryUrl: 'https://commerce.getnoddit.com/delivery-api/api/v1.0',
  lookupUrl: 'https://api.getnoddit.com/merchant/api/v1/Lookup',
  orderUrl: 'https://commerce.getnoddit.com/order-api/api/v1.0',
  packageUrl: 'https://commerce.getnoddit.com/package-api/api/v1.0',
  shopUrl: 'https://commerce.getnoddit.com/shop/api/v1.0',
  clientId: '6fvvwwMTzQRTXzUVyplGkXsn4n9ry3Jz',
  authority: 'https://auth.getnoddit.com',
  scopes: 'openid profile read:store shop:store delete:user offline_access',
  hubUrl: 'https://commerce.getnoddit.com/orderhub',
  googleKey: 'AIzaSyCMP7YEkJbbrsVKxme3s6uZUn8JD92QQLc',
  PushNotificationUrl:
    'https://api.getnoddit.com/Notification/api/v1/PushNotificationRegistration',
  firebase: {
    apiKey: 'AIzaSyCNn7aMHFCKOIAGs60rX0ppBP1E4S4GecM',
    authDomain: 'moolahvest.firebaseapp.com',
    databaseURL: 'https://moolahvest-default-rtdb.firebaseio.com',
    projectId: 'moolahvest',
    storageBucket: 'moolahvest.appspot.com',
    messagingSenderId: '747904334587',
    appId: '1:747904334587:web:51ee995ce097556f040e5e',
    measurementId: 'G-1ED8J9VTXY',
  },
  CheckOutUrl: 'com.getnoddit.shop://checkout/status',
  mobileRedirectUrl: 'com.getnoddit.shop://',
  packageMobileRedirectUrl: 'com.getnoddit.shop://package',
  nodditShopDownloadUrl: 'https://onelink.to/bnyz3v',
  nodditFinanceDownloadUrl: 'https://onelink.to/a6f7u5',
  SentryUrl:
    'https://01777eee510f4e5ae85a0891a3dc4eff@o4506863163932672.ingest.us.sentry.io/4506894277476352',
  ContentUrl:
    'https://api.getnoddit.com/ContentManagement/api/v1/ContentManagement',
  SiteVisitUrl: 'https://api.getnoddit.com/referrals/api/v1/add-site-visits',
  fireBaseDb: {
    apiKey: 'AIzaSyBUpKLY69RT4ECllLjqtrJUnXNoNroZUfw',
    authDomain: 'package-24bc8.firebaseapp.com',
    projectId: 'package-24bc8',
    storageBucket: 'package-24bc8.appspot.com',
    messagingSenderId: '457339794070',
    appId: '1:457339794070:web:edf458abb6c0e72f5848b7',
    measurementId: 'G-7ZHC4MS30M',
  },
  firebaseMessageConfig: {
    apiKey: 'AIzaSyCNn7aMHFCKOIAGs60rX0ppBP1E4S4GecM',
    authDomain: 'moolahvest.firebaseapp.com',
    databaseURL: 'https://moolahvest-default-rtdb.firebaseio.com',
    projectId: 'moolahvest',
    storageBucket: 'moolahvest.appspot.com',
    messagingSenderId: '747904334587',
    appId: '1:747904334587:web:51ee995ce097556f040e5e',
    measurementId: 'G-1ED8J9VTXY',
    vapidKey:
      'BJaWhPm-eOEAjYD5xlShobwOD1xqtrhnuN7Un0Js0T5hgDvKDJEIfAN_64hAEO87R1PFZoGGmQSC0U2moEXf2-s',
  },
  faceBookPixelId: '3808696986062574',
};
