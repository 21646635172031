import { Component, Input, OnInit } from '@angular/core';
import { Store } from 'src/app/stores/models/stores.model';

@Component({
  template: `
    <ion-header class="ion-no-border">
      <ion-row
        class="bg-danger closed-info ion-justify-content-between ion-align-items-center"
      >
        <ion-badge class="closed-badge" color="transparent">
          <ion-icon
            class="v-middle"
            color="light"
            name="lock-closed-outline"
          ></ion-icon>
          <ion-label class="v-middle closed-label" color="light"
            >Store Closed</ion-label
          >
        </ion-badge>
        <ion-label
          *ngIf="store?.openingTime"
          color="light"
          class="opening-time"
        >
          Opens {{ store?.openingTime | date : 'h:mm a' }}
        </ion-label>
      </ion-row>
    </ion-header>
  `,
  selector: 'app-store-closed',
  styles: [
    `
      .opening-time {
        font-size: 0.9rem;
        margin-right: 0.2rem;
      }

      .closed-info {
        padding: 0.3rem;
      }
    `,
  ],
})
export class StoreClosedComponent implements OnInit {
  @Input() store: Store | undefined;

  constructor() {}

  ngOnInit(): void {}
}
