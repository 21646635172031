import { Component } from '@angular/core';
import { Device } from '@capacitor/device';

@Component({
  template: `<ion-toolbar *ngIf="isIOs"> </ion-toolbar>`,
  selector: 'ios-toolbar',
})
export class IosToolBarComponent {
  isIOs: boolean = false;

  constructor() {
    Device.getInfo().then(result=> {
      this.isIOs= result.platform ==='ios'
    })
  }
}
