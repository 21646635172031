import { AfterViewChecked, AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationController, GestureController, IonItem, Animation } from '@ionic/angular';

const ANIMATION_BREAKPOINT = 70;

@Component({
  selector: 'app-swipe-item',
  styleUrls: ['../styles/swipe-item.component.scss'],
  templateUrl: '../views/swipe-item.component.html',
})
export class SwipeItemComponent implements OnInit, AfterViewInit, AfterViewChecked  {
  @ContentChild(TemplateRef) template!: TemplateRef<any>;
	item!: HTMLElement;
	@ViewChild('dymanicTemplateRef', { read: ElementRef }) dymanicTemplateRef!: ElementRef;
	@ViewChild('wrapper') wrapper: ElementRef | undefined;
	@ViewChild('trash', { read: ElementRef, static: false }) trashIcon!: ElementRef;
	@ViewChild('archive', { read: ElementRef }) archiveIcon!: ElementRef;
  @Input() leftIcon = 'trash-outline';
  @Input() rightIcon = 'archive-outline';

  @Input() leftTheme: 'danger' | 'success' | 'warning' | 'primary' | 'success' | 'tertiary' | 'secondary'| 'dark' | 'medium' | 'light' = 'danger';
  @Input() rightTheme: 'danger' | 'success' | 'warning' | 'primary' | 'success' | 'tertiary' | 'secondary'| 'dark' | 'medium' | 'light' = 'success';

	@Output() swipeLeft: EventEmitter<any> = new EventEmitter();
	@Output() swipeRight: EventEmitter<any> = new EventEmitter();
  isRemoved = false;

	bigIcon = false;
  initialHeight = 0;

	trashAnimation!: Animation;
	archiveAnimation?: Animation;
	deleteAnimation?: Animation;
	restoreAnimation?: Animation;


  ngOnInit() {
  }

  viewBasket(storeId: string) {
    this.router.navigate(['/', 'baskets', storeId]);
  }
	constructor(
		private router: Router,
		private gestureCtrl: GestureController,
		private animationCtrl: AnimationController
	) {}

  ngAfterViewChecked(): void {
    this.item = this.dymanicTemplateRef.nativeElement.previousSibling;
		this.setupIconAnimations();
    let itemHeight = document.getElementById('swipe-box')?.getBoundingClientRect().height;
    if (itemHeight && itemHeight > this.initialHeight) this.initialHeight = itemHeight;

		const style = this.item.style;
		const windowWidth = window.innerWidth;

    this.initAnimations();

		const moveGesture = this.gestureCtrl.create({
			el: this.item,
			gestureName: 'move',
			threshold: 0,
			onStart: (ev) => {
				style.transition = '';
			},
			onMove: (ev) => {
				// Make the item stand out
				this.item.classList.add('swipe-rounded');

				if (ev.deltaX > 0) {
          if (this.wrapper)
					this.wrapper.nativeElement.style['background-color'] = `var(--ion-color-${this.leftTheme})`;
					style.transform = `translate3d(${ev.deltaX}px, 0, 0)`;
				} else if (ev.deltaX < 0) {
          if (this.wrapper)
					this.wrapper.nativeElement.style['background-color'] = `var(--ion-color-${this.rightTheme})`;
					style.transform = `translate3d(${ev.deltaX}px, 0, 0)`;
				}
        // else {
        //   if (this.wrapper)
				// 	style.transform = `none`;
        // }

				// Check if we need to animate trash icon
				if (ev.deltaX > ANIMATION_BREAKPOINT && !this.bigIcon) {
					this.animateTrash(true);
				} else if (ev.deltaX > 0 && ev.deltaX < ANIMATION_BREAKPOINT && this.bigIcon) {
					this.animateTrash(false);
				}

				// Check if we need to animate archive icon
				if (ev.deltaX < -ANIMATION_BREAKPOINT && !this.bigIcon) {
					this.animateArchive(true);
				} else if (ev.deltaX < 0 && ev.deltaX > -ANIMATION_BREAKPOINT && this.bigIcon) {
					this.animateArchive(false);
				}
			},
			onEnd: (ev) => {
				style.transition = '0.2s ease-out';
				this.item.classList.remove('swipe-rounded');

				// Check if we are past the delete or archive breakpoint
				if (ev.deltaX > ANIMATION_BREAKPOINT) {
					style.transform = `translate3d(${windowWidth}px, 0, 0)`;
					this.deleteAnimation?.play();
					this.deleteAnimation?.onFinish(() => {
						this.swipeRight.emit(true);
            this.initAnimations();
					});
				} else if (ev.deltaX < -ANIMATION_BREAKPOINT) {
					style.transform = `translate3d(-${windowWidth}px, 0, 0)`;
					this.deleteAnimation?.play();
					this.deleteAnimation?.onFinish(() => {
						this.swipeLeft.emit(true);
            this.initAnimations();
					});
				} else {
					style.transform = 'none';
				}
			}
		});

		// Don't forget to enable!
		moveGesture.enable(true);

  }

	ngAfterViewInit() {}

	setupIconAnimations() {}

	animateTrash(zoomIn: any) {}

	animateArchive(zoomIn: any) {}

	openDetails(id: any) {}

  restoreItem() {
		const style = this.item.style;
    style.transform = 'none';
    this.restoreAnimation?.play();
    this.restoreAnimation?.onFinish(() => {
    });
  }

  initAnimations() {
		this.deleteAnimation = this.animationCtrl
			.create('delete-animation')
			.addElement(this.item)
			.duration(300)
			.easing('ease-out')
			.fromTo('height', `${this.initialHeight? this.initialHeight: '89px'}px`, '0');

		this.restoreAnimation = this.animationCtrl
			.create('restore-animation')
			.addElement(this.item)
			.duration(300)
			.easing('ease-out')
			.fromTo('height', '0', `${this.initialHeight? this.initialHeight: '89px'}px`);
  }

}
