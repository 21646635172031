
<div class="bg-grey pt-sm" *ngIf="contents.length > 0">
  <div class="bg-light ion-padding-vertical">
    <div>
      <drag-scroll class="ad-scroll">
          <div (click)="select(ad)" drag-scroll-item class="ad-box" *ngFor="let ad of contents">
            <ion-img class="ad-img" [src]="ad.mediaAssets[0].url"></ion-img>
          </div>
      </drag-scroll>
    </div>

  </div>
</div>
