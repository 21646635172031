<ion-content>
    <ion-grid *ngIf="transactionType!==beneficiaryTypes.Transfer" style="min-height: 100%;">
        <ion-row>
            <ion-col>
                <ion-row class="ion-padding-top">
                    <ion-col class="ion-padding-top ion-text-center">
                        <ion-icon *ngIf="status===allTransactionStatus.Succesful" class="status-icon" style="font-size: 30px; background-color: var(--ion-color-primary); padding: 20px; border-radius: 18px;" src="assets/icon/check-mark.svg" [color]="'light'"></ion-icon>
                        <ion-icon *ngIf="status!==allTransactionStatus.Succesful" class="status-icon" style="font-size: 30px; background-color: var(--ion-color-danger); padding: 20px; border-radius: 18px;" src="assets/icon/error.svg" [color]="'light'"></ion-icon>
                    </ion-col>
                </ion-row>

                <ion-row>
                    <ion-col class="ion-text-center">
                        <ion-label class="ion-text-wrap">
                            {{status===allTransactionStatus.Succesful ? message: 'Transaction Failed'}}
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="status!==allTransactionStatus.Succesful">
                    <ion-col class="ion-text-center">
                        <ion-label class="ion-text-wrap">
                            <sub>
                                {{message}}
                            </sub>
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col class="ion-text-center">
                        <ion-label class="ion-text-wrap">
                            <sub>
                                Ref: {{reference}}
                            </sub>
                        </ion-label>
                    </ion-col>
                </ion-row>

                <ion-row>
                    <ion-col class="ion-no-padding">
                        <ion-list lines="none">
                            <ion-item color="light">
                                <ion-icon class="full-icon no-margin-right" size="large" slot="start" src="assets/icon/{{status===allTransactionStatus.Succesful?'success':'error'}}.svg" [color]="status===allTransactionStatus.Succesful?'success':'danger'"></ion-icon>
                                <ion-row>
                                    <ion-col>
                                        <ion-row>
                                            <ion-col>
                                                <ion-label color="dark" class="ion-text-wrap">
                                                    <ion-text class="heading">{{transactionName}}</ion-text>
                                                </ion-label>
                                            </ion-col>
                                        </ion-row>
                                    </ion-col>
                                    
                                </ion-row>
                                <ion-col class="ion-text-end light">
                                    <ion-text color="dark" class="heading">{{amount |  currency : '&#8358;' : 'symbol' : '1.0-0'}}</ion-text>
                                </ion-col>
                            </ion-item>
                        </ion-list>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="status===allTransactionStatus.Succesful">
                    <ion-col class="ion-text-center ion-padding-top">
                        <ion-row>
                            <ion-col class="ion-fab ion-no-padding">
                                <ion-fab-button size="small" (click)="dismiss()"><ion-icon color="light" src="assets/icon/download.svg"></ion-icon></ion-fab-button>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <ion-label>
                                    <sub>Download</sub>
                                </ion-label>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                    <ion-col *ngIf="status===allTransactionStatus.Succesful" class="ion-text-center ion-padding-top">
                        <ion-row>
                            <ion-col class="ion-fab ion-no-padding">
                                <ion-fab-button size="small" (click)="dismiss()"><ion-icon color="light" src="assets/icon/schedule.svg"></ion-icon></ion-fab-button>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <ion-label>
                                    <sub>
                                    Schedule
                                </sub>
                                </ion-label>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                    <ion-col class="ion-text-center ion-padding-top">
                        <ion-row>
                            <ion-col class="ion-fab ion-no-padding">
                                <ion-fab-button size="small" (click)="dismiss()"><ion-icon color="light" src="assets/icon/share.svg"></ion-icon></ion-fab-button>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <ion-label>
                                    <sub>
                                    Share
                                </sub>
                                </ion-label>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-no-padding ion-padding-top">
                    <ion-col class="ion-text-center">
                        <ion-label>
                            <ion-card-subtitle><sub>Total Amount</sub></ion-card-subtitle>
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col class="ion-text-center">
                        <div style="display: inline-block; position: relative;"><span style="display: inline-block;vertical-align: top; padding-top: 6px;">&#8358;</span><h3 style="display: inline-block; margin-top: 0; font-size: 30px;">
                            {{amount ? (amount+surCharge| number) : (0 | number) }}
                        </h3>
                    </div>
                    </ion-col>
                </ion-row>
            </ion-col>
        </ion-row>
    </ion-grid>
    <ion-grid *ngIf="transactionType===beneficiaryTypes.Transfer" class="center" style="min-height: 100%;">
        <ion-row>
            <ion-col class="ion-text-center">
                <ion-row>
                    <ion-col class=" ion-text-center">
                        <ion-icon *ngIf="status===allTransactionStatus.Succesful" class="status-icon" style="font-size: 30px; background-color: var(--ion-color-primary); padding: 20px; border-radius: 18px;" src="assets/icon/check-mark.svg" [color]="'light'"></ion-icon>
                        <ion-icon *ngIf="status!==allTransactionStatus.Succesful" class="status-icon" style="font-size: 30px; background-color: var(--ion-color-danger); padding: 20px; border-radius: 18px;" src="assets/icon/error.svg" [color]="'light'"></ion-icon>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="status===allTransactionStatus.Succesful" class="ion-padding-top ion-text-center">
                    <ion-col class="ion-padding-top ion-text-center">
                        <ion-label class="ion-text-wrap">
                            You just sent {{amount |  currency : '&#8358;' : 'symbol' : '1.0-0'}} to 
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="status===allTransactionStatus.Succesful">
                    <ion-col class="ion-text-center">
                        <ion-title class="ion-text-wrap">
                            <ion-label class="ion-text-wrap">
                                {{transactionName}}
                            </ion-label>
                        </ion-title>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="status!==allTransactionStatus.Succesful">
                    <ion-col class="ion-text-center">
                        <ion-title>
                            <ion-label class="ion-text-wrap">
                                Transfer Failed!
                            </ion-label>
                        </ion-title>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="status!==allTransactionStatus.Succesful">
                    <ion-col class="ion-text-center">
                        <ion-label class="ion-text-wrap">
                                {{message}}
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col class="ion-text-center">
                        <ion-label class="ion-text-wrap">
                            <sub>
                                Ref: {{reference}}
                            </sub>
                        </ion-label>
                    </ion-col>
                </ion-row>
            </ion-col>
        </ion-row>
        
    </ion-grid>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-grid>
        <ion-row>
            <ion-col class="ion-text-center">
                <ion-button shape="round" fill="outline" class="btn-block btn-round" (click)="dismiss()">Done</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>