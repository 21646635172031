import { Component, Input, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
    templateUrl:'../views/pop-alert.component.html',
    selector : 'pop-alert-box'
})

export class PopAlertComponent implements OnInit{
  @Input() type!:  'success' | 'error' | 'info';
  @Input() text: string = '';
  @Input() title: string = '';
  @Input() buttonText: string = 'Got It';
  @Input() buttonFill: 'solid' | 'outline' | 'clear' = 'solid';
  alerts = {
    success: {
      icon: 'success',
      color: 'success'
    },
    error: {
      icon: 'error',
      color:'danger'
    },
    warning: {
      icon: 'info',
      color:'warning'
    }
  }
  constructor(private popoverCtrl: PopoverController){}

  ngOnInit(): void {
  }

  onClose() {
    this.popoverCtrl.dismiss({}, 'confirm');
  }
}
