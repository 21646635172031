import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwipeItemComponent } from 'src/app/Shared/components/swipe-item.component';
import { Store } from 'src/app/stores/models/stores.model';
import { BasketService } from '../../basket/basket.service';
import { BasketItem } from '../../basket/models/basket.model';

@Component({
  selector: 'app-basket-item',
  templateUrl: '../views/basket-item.component.html',
  styleUrls: ['../styles/basket-item.component.scss'],
})
export class BasketItemComponent implements OnInit {
  @Input() store?: Store;
  @Input() item!: BasketItem;
  @Input() index!: number;
  @Input() lines: 'inset' | 'full' | 'none' = 'inset';
  @Input() showButtons = false;
  @Input() showSecondaryButtons = false;
  @Input() showLogo = false;
  @Input() showSideCount = false;


  constructor(
    private router: Router,
    public basketService: BasketService,
    private cdr: ChangeDetectorRef
    ) {}

  ngOnInit() {
  }

  incrementQuantity(duplicate=false) {
    if (!this.store) return;
    this.basketService.incrementItemQuantity(this.store, this.index, 1, duplicate);
  }

  removeItem(quantity=1) {
    if (!this.store) return;
    this.basketService.removeItemFromBasket(this.store.storeId, this.index, quantity).subscribe();
  }

  editItem() {
    this.router.navigate(['/', 'products', 'detail'], {
      queryParams:{
        storeId: this.store?.storeId,
        productId: this.item.productId,
        edit: this.index
      }
    });
  }

  deleteItem(swipeItem: SwipeItemComponent) {
    if (!this.store) return;
    this.basketService.removeItemFromBasket(this.store.storeId, this.index, this.item.quantity).subscribe({
      next: res=> {
        this.cdr.detectChanges();
      }, error: err=> {
        swipeItem.restoreItem();
      }
    });
  }
}
