import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';


@Injectable({
  providedIn: "root",
})

export class SweetAlertService {

  constructor() {}

  showAlert(type: SweetAlertIcon, title: string, message: string, showConfirmButton= true, showCancelButton=false) {
    return Swal.fire({
      title,
      text: message,
      icon: type,
      showConfirmButton,
      heightAuto: false,
      showCancelButton,
      customClass: {
        cancelButton: 'btn btn-lg btn-light-primary order-1',
        confirmButton: 'btn btn-lg btn-primary order-2'
      }
    });
  }
}
