export enum DeviceTypes {
  Android = 1,
  IOS = 2,
  Web = 3,
}

export enum Apps {
  Ecommerce = 1,
  Finance = 2,
}

export enum NotificationTypes {
  orderDetails = 'order_details',
  orderReview = 'order_review',
  generic='generic'
}

export interface NotificationData {
  id: string;
  title:string;
  type: NotificationTypes;
  imageUrl:string;
  link:string;
}
