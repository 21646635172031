
export enum DayOfWeek {
  Sunday = 0,
  //
  // Summary:
  //     Indicates Monday.
  Monday = 1,
  //
  // Summary:
  //     Indicates Tuesday.
  Tuesday = 2,
  //
  // Summary:
  //     Indicates Wednesday.
  Wednesday = 3,
  //
  // Summary:
  //     Indicates Thursday.
  Thursday = 4,
  //
  // Summary:
  //     Indicates Friday.
  Friday = 5,
  //
  // Summary:
  //     Indicates Saturday.
  Saturday = 6
}

export interface AddStoreOpeningHours {
  storeId: string,
  openingHours: StoreOpeningHours[]
}

export interface StoreOpeningHours {
  day: DayOfWeek | StoreDays,
  openingTime: Date | string,
  closingTime: Date | string,
  workingToday?: boolean
}

export interface StoreDays {
  id: DayOfWeek,
  cityCode: string,
  name: string
}
