import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'enumToArray'
})

export class EnumToArrayPipe implements PipeTransform {
    transform(data: any) {
      let values = Object.values(data);
    return values.slice(0, values.length/2).map((x: any)=> {
      return {
        id: (data[x] as unknown) as number,
        name: x
      }
    });
    }
}
