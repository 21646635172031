<ion-grid>
    <ion-row>
        <ion-col>
            <ion-card>
                <ion-list lines="full">
                    <ion-item [routerLink]="['/'].concat(notification.link.split('/'))" *ngFor="let notification of notifications" class="transaction-bar" detail>
                        <ion-icon id="transfer" class="full-icon menu-icon transaction-icon" slot="start" src="assets/icon/{{notification.icon}}.svg" color="primary"></ion-icon>
                        <ion-col>
                            <ion-row>
                                <ion-col class="ion-no-padding">
                                    <ion-title class="notification-title">{{notification.title}}</ion-title>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col class="ion-no-padding">
                                    <ion-card-subtitle class="notification-content">{{notification.description}}</ion-card-subtitle>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                </ion-item>
            </ion-list>
            </ion-card>
        </ion-col>
    </ion-row>
</ion-grid>