import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'app-text-input',
  styleUrls: ['../styles/text-input.scss'],
  templateUrl: '../views/text-input.html',
})
export class TextInputPage implements OnInit, ControlValueAccessor {
  @ViewChild('input', { static: true }) input!: ElementRef;
  @Input() type = 'text';
  @Input() label = '';
  @Input() displayText = '';
  @Input() disabled = false;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() errorMatcher: any;
  @Input() passwordInput = false;
  showPassword = false;

  constructor(@Self() public controlDir: NgControl) {
    this.controlDir.valueAccessor = this;
  }

  ngOnInit(): void {
    const control = this.controlDir.control;
    const validators = control?.validator ? [control?.validator] : [];
    const asyncValidators = control?.asyncValidator
      ? [control?.asyncValidator]
      : [];
    control?.setValidators(validators);
    control?.setAsyncValidators(asyncValidators);
    control?.updateValueAndValidity();
    if (this.type == 'password') this.passwordInput = true;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  onChange(event: any) {}

  onTouched() {}

  writeValue(obj: any): void {}

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}
  get control(): FormControl {
    return this.controlDir.control as FormControl;
  }
}
