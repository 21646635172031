import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderService } from 'src/app/services/order.service';
import { Order } from 'src/app/stores/models/orders.model';
import { AddRating, FeedBack, FeedBackTypeEnum, ReviewTypeEnum } from '../models/rating.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-rate-delivery',
  templateUrl: '../views/rate-delivery.page.html',
  styleUrls: ['../styles/rate-delivery.page.scss'],
})
export class RateDeliveryPage implements OnInit {
  form!: FormGroup;
  private unsubscribe: Subscription[] = [];
  @Input() order!: Order;
  @Output() complete = new EventEmitter<boolean>();
  feedbackPositive: FeedBack[] = [];
  feedbackNegative: FeedBack[] = [];
  formChanged = false;
  @Input() updateDriverRating!: (
    part: Partial<AddRating>,
    isFormValid: boolean
  ) => void;

  constructor(
    private orderService: OrderService,
    private fb: FormBuilder
    ) {}

  ngOnInit() {
    this.initFeedBack();
    this.form = this.fb.group({
      like: [undefined, Validators.required],
      feedback: ['', Validators.required]
    });
    const formSb = this.form.valueChanges.subscribe((val)=> {
      this.formChanged = true;
      this.updateDelivery(val, this.form?.valid);
    });
    if (formSb) this.unsubscribe.push(formSb);
  }

  initFeedBack() {
    const fbNegSb = this.orderService.getFeedBackByFeedBack(ReviewTypeEnum.Driver, FeedBackTypeEnum.Bad).subscribe({
      next: res=> {
        if (!res) return;
        this.feedbackNegative = res;
      }
    });
    this.unsubscribe.push(fbNegSb);

    const fbPosSb = this.orderService.getFeedBackByFeedBack(ReviewTypeEnum.Driver, FeedBackTypeEnum.Good).subscribe({
      next: res=> {
        if (!res) return;
        this.feedbackPositive = res;

      }
    });
    this.unsubscribe.push(fbPosSb);
  }

  updateDelivery(product: {like: boolean, feedback: FeedBack}, isValid: boolean) {
    let ratings: Partial<AddRating> = {
        rating: product.like ? 5: 1,
        description: product.feedback?.name,
        feedBackTypeId: product.feedback?.id,
      };
    this.updateDriverRating(ratings, isValid);
  }

  submit() {
    this.complete.emit(true);
  }

  setRating(rating: boolean) {
    this.form.get('like')?.setValue(rating);
    this.form.get('feedback')?.setValue(undefined);
  }

  setFeedback(feedback: FeedBack) {
    this.form.get('feedback')?.setValue(feedback);
  }

}
