
<ion-item [lines]="lines" class="cart-item">
  <ion-grid class="ion-no-padding">
      <ion-row class="main-row">
        <div class="mr-sm d-flex ion-align-items-start">
          <ion-badge class="side-badge">{{ item.quantity }}</ion-badge>
        </div>
        <div class="main-col">
            <ion-row>
                <ion-col class="ion-no-padding">
                    <ion-label class="truncate-2 heading-md">{{item.productName}}</ion-label>
                    <ion-label class="ion-text-wrap sub-heading-md" *ngFor="let option of item.cartOption"><b>{{option.name}}:</b><span *ngFor="let variation of option.variations; let v=index">{{ v>0? ', ': ' ' }}{{variation.name}}{{variation.price ? ' ('+(variation.price*variation.quantity |  currency : '&#8358;' )+')' : ''}}</span></ion-label>
                    <!-- <ion-label class="truncate mt-sm sub-heading-md">{{ item.price * item.quantity |  currency : '&#8358;' : 'symbol' : '1.0-0'}}</ion-label> -->
                </ion-col>
            </ion-row>
            <ion-row *ngIf="showButtons" class="ion-align-items-center">
                <ion-col size="12" class="ion-no-padding mt-sm">
                    <div style="display: inline-flex;">
                        <ion-button fill="clear" class="delete-btn" (click)="removeItem()">
                            <ion-icon slot="icon-only" size="small" [name]="item.quantity > 1 ? 'remove-outline': 'trash-outline'"></ion-icon>
                        </ion-button>
                        <ion-label class="ion-align-self-center ion-padding-horizontal">{{item.quantity}}</ion-label>
                        <ion-button fill="clear" class="reduce-btn" (click)="incrementQuantity()">
                            <ion-icon slot="icon-only" size="small" name="add-outline"></ion-icon>
                        </ion-button>
                    </div>
                </ion-col>
                <ion-col class="ion-no-padding mt-sm" size="12" *ngIf="item.cartOption?.length && showSecondaryButtons">
                  <ion-button (click)="editItem()" size="small" color="medium" shape="round" class="mr-sm no-margin-bottom ion-text-capitalize">Edit Selection</ion-button>
                  <ion-button (click)="incrementQuantity(true)" size="small" fill="outline" color="primary" shape="round" class="border-1 no-margin-bottom ion-text-capitalize">Duplicate Item</ion-button>
                </ion-col>
            </ion-row>
        </div>
        <div class="aux-col">
          <ion-thumbnail *ngIf="showLogo" class="item-logo mb-sm ion-align-self-start">
            <ion-img [src]="item.pictureUrl | encode"></ion-img>
          </ion-thumbnail>
          <ion-label class="truncate-2 ion-text-center heading-md">{{ item.price * item.quantity |  currency : '&#8358;' : 'symbol' : '1.0-0'}}</ion-label>
        </div>
      </ion-row>
  </ion-grid>
</ion-item>
