<ion-content class="container">
  <ion-button fill="clear" class="close-btn" (click)="dismiss()">
      <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
  <ion-grid class="center">
    <ion-row *ngIf="title">
      <ion-col size="12" class="ion-padding-horizontal">
        <h3 class="title">{{title}}</h3>
      </ion-col>
    </ion-row>
    <ion-row class="ion-text-center">
      <ion-col size="12" class="ion-padding-horizontal" *ngIf="form" [formGroup]="form">
        <div *ngFor="let input of inputFields">
          <div *ngIf="!input.otpInput">
            <!-- <app-text-input
              *ngIf="!input.hasDropDown"
              [label]="input.name"
              [type]="input.type || ''"
              [formControlName]="input.name"
            >
            </app-text-input> -->
            <mat-form-field
            class="phone-input"
            appearance="outline"
            style="width: 100%;outline: none !important;">
            <mat-label class="label">{{input.name}}</mat-label>
            <input
            matInput
            [type]="input.type || 'text'"
            [formControlName]='input.id'
            [placeholder]="input.name"
            required>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              *ngIf="input.hasDropDown"
              style="width: 100%"
            >
              <mat-label>{{input.name}}</mat-label>
              <mat-select [formControlName]="input.name" required>
                <mat-option [value]="null">None</mat-option>
                <mat-option
                  *ngFor="let dropDown of input.dropDown"
                  style="text-transform: capitalize"
                  [value]="dropDown.id"
                  >{{dropDown.name}}</mat-option
                >
              </mat-select>
              <ion-icon
                matSuffix
                name="alert-circle-outline"
                class="invalid"
                *ngIf="(form.get(input.name)?.errors?.['required']
                                && form.get(input.name)?.touched)"
              >
              </ion-icon>
              <ion-icon
                matSuffix
                name="checkmark-outline-sharp"
                *ngIf="(form.get(input.name)?.errors === null
                                && form.get(input.name)?.touched)"
                class="valid"
              >
              </ion-icon>
              <mat-error *ngIf="form.get(input.name)?.errors?.['required']">
                {{input.name}} is required
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="input.otpInput">
            <ion-row>
              <ion-col class="ion-text-center">
                <ion-text> {{ input.displayText }} </ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="ion-text-center" size="12">
                <ng-otp-input
                  (onInputChange)="onOtpChange($event,input)"
                  [config]="{
                                    length: input.otpTextLength || 4,
                                    inputClass: input.otpClass || 'tp-input',
                                    isPasswordInput: input.otpHidden,
                                    allowNumbersOnly: true
                                            }"
                  ><ion-text> {{ input.displayText }} </ion-text>
                </ng-otp-input>
              </ion-col>
              <ion-col size="12"
              *ngIf="input.otpError">
                <mat-error>
                {{input.otpError}}
                </mat-error>
              </ion-col>
            </ion-row>
          </div>
        </div>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!inputFields[0].otpInput">
      <ion-col class="ion-padding-horizontal ion-padding-top ion-text-center">
        <ion-button class="btn-md" [disabled]="form.invalid" (click)="submit()" shape="round" size="large">
          <ion-label class="ion-text-capitalize">Continue</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
