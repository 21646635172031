import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";


@Component({
    templateUrl:'../views/add-notes.component.html',
    selector : 'app-add-notes'
})
export class AddNotesComponent implements OnInit {

  @Input() notes = '';

  constructor(private modalCtrl: ModalController){}

  ngOnInit(): void {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  submit() {
    this.modalCtrl.dismiss(this.notes, 'confirm');
  }
}
