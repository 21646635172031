<ion-header class="ion-no-border">
  <ion-toolbar [color]="'light'">
      <ion-buttons slot="primary">
          <ion-button [color]="'primary'" size="small" (click)="dismiss()">
              <ion-icon slot="icon-only" class="search-icon" name="close-outline"></ion-icon>
          </ion-button>
      </ion-buttons>
      <!-- <ion-title [color]="'primary'" class="ion-text-start">Select Payment Method</ion-title> -->
    </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid class="no-padding-top">
    <ion-row class="ion-justify-content-center">
        <ion-col class="ion-no-padding ion-padding-horizontal">
            <h3 class="header-title">
              Payment Options
            </h3>
        </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-margin-bottom">
        <ion-col class="ion-padding-horizontal ion-padding-bottom">
            <ion-label class="description">
            </ion-label>
            <ion-card-subtitle *ngIf="paymentOptions.length">
              Choose your prefered payment method for your items.
            </ion-card-subtitle>
            <ion-card-subtitle *ngIf="!paymentOptions.length">
              No payment options set up for this store.
            </ion-card-subtitle>
        </ion-col>
    </ion-row>
    <ion-list lines="full" class="no-padding-top ion-padding-start">
      <ion-item [ngClass]="{ 'active': option.paymentOptionId===selectedOption?.paymentOptionId }" (click)="selectPaymentOption(option)" *ngFor="let option of paymentOptions" button="true" detail class="payment-item">
        <ion-icon *ngIf="option.paymentOptionId===selectedOption?.paymentOptionId" color="primary" class=" ion-margin-end" slot="start" name="checkmark-circle-sharp"></ion-icon>
        <ion-icon *ngIf="option.paymentOptionId!==selectedOption?.paymentOptionId" slot="start" color="dark" class="ion-margin-end"
        [name]="option.paymentOptionId===paymentMethodOption.Account ? 'wallet-sharp': option.paymentOptionId===paymentMethodOption.Cash? 'cash-sharp': option.paymentOptionId===paymentMethodOption.Loan? 'wallet-sharp': option.paymentOptionId===paymentMethodOption.Transfer ? '': 'card'"  [src]="option.paymentOptionId===paymentMethodOption.Transfer ? 'assets/icon/transfer.svg': ''"></ion-icon>
        <div>
          <ion-label class="ion-no-padding order-label" [color]="option.paymentOptionId===selectedOption?.paymentOptionId ? 'primary': 'dark'">{{(option?.paymentOptionId===paymentMethodOption.Card) && (option?.paymentOptionId===selectedOption?.paymentOptionId) ? selectedOption?.paymentOption: option.paymentOption}}</ion-label>
          <ion-card-subtitle class="order-subtitle mt-sm">{{option?.description}}</ion-card-subtitle>
          <!-- <ion-card-subtitle class="order-subtitle">{{ '2010-1-14' | date }} &#x2022; Completed</ion-card-subtitle> -->
        </div>
      </ion-item>
    </ion-list>

  </ion-grid>
</ion-content>
<ion-footer class="ion-no-border ion-padding-bottom">
  <ion-row>
      <ion-col>
          <ion-button [disabled]="!selectedOption" expand="full" shape="round" class="btn-md ion-text-capitalize" (click)="submit()">Done</ion-button>
      </ion-col>
  </ion-row>
</ion-footer>
