import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/products/models/products.model';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-popular-items',
  styleUrls: ['../styles/popular-items.scss'],
  templateUrl: '../views/popular-items.html',
})
export class PopularItemsComponent implements OnDestroy, OnChanges {
  private unsubscribe: Subscription[] = [];
  products: Product[] = [];
  products$: Observable<Product[]> = new Observable<Product[]>();
  @Input() city: string | undefined;
  @Input() location: string | undefined;
  constructor(public storeService: StoreService, private router: Router) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['city'] || changes['location']) {
      if (this.city) {
        this.getPopularProducts();
      }
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  getPopularProducts() {
    if (this.city) {
      this.products$ = this.storeService.getPopularProducts(
        this.city,
        this.location ?? ''
      );
    }
  }

  selectProduct(product: Product) {
    this.router.navigate(['/', 'products', 'detail'], {
      queryParams: {
        storeId: product.storeId,
        productId: product.id,
      },
    });
  }
}
