<ion-header>
  <ion-toolbar [color]="'light'">
      <ion-buttons slot="primary">
          <ion-button [color]="'primary'" size="small" (click)="dismiss()">
              <ion-icon slot="icon-only" class="search-icon" name="close-outline"></ion-icon>
          </ion-button>
      </ion-buttons>
      <ion-title [color]="'primary'" class="ion-text-center">Add Note</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid class="h-100">
    <ion-textarea placeholder="Type something here" [autoGrow]="true"
      [(ngModel)]="notes">
    </ion-textarea>
  </ion-grid>
</ion-content>
<ion-footer class="ion-no-border ion-padding-bottom">
  <ion-row>
      <ion-col>
          <ion-button [disabled]="!notes" expand="full" shape="round" class="btn-md ion-text-capitalize" (click)="submit()">Done</ion-button>
      </ion-col>
  </ion-row>
</ion-footer>
