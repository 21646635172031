import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Preferences } from '@capacitor/preferences';
import { ApiService } from './Api.service';
import { AllContents, Content, ContentArea } from '../models/advert.model';

@Injectable({
  providedIn: 'root',
})
export class AdvertService extends ApiService {
  baseUrl = `${environment.ContentUrl}`;
  private _allContents = new BehaviorSubject<AllContents[]>([]);
  private _onboardingContent = new BehaviorSubject<Content[]>([]);
  private _whatsNew = new BehaviorSubject<Content[]>([]);
  onboardContent$ = this._onboardingContent.asObservable();
  whatsNewContent$ = this._whatsNew.asObservable();
  contentSub?: Subscription;

  constructor(httpClient: HttpClient, private zone: NgZone) {
    super(httpClient);
  }

  getContent(contentArea: ContentArea) {
    if (contentArea == ContentArea.Onboarding) {
      let defaultContent: Content[] = [];
      this.getDefaultContent().then((res) => {
        defaultContent = res;
      });
      if (defaultContent.length <= 0) {
        return this.fetchContentFromApi(contentArea);
      }
      const allContents: AllContents[] = [];
      allContents.push({
        contentArea: ContentArea.Onboarding,
        contents: defaultContent,
      });
      this._allContents.next(allContents);
      this.zone.runOutsideAngular(() => {
        this.fetchContentFromApi(contentArea).subscribe(
          (data: any) => this.saveContent(data),
          () => {}
        );
      });
      return of(defaultContent);
    }

    let allContent = this._allContents?.value?.find(
      (x) => x.contentArea == contentArea
    );
    if (allContent && allContent.contents.length > 0)
      return of(allContent.contents);

    return this.fetchContentFromApi(contentArea);
  }

  async getDefaultContent() {
    const data = await Preferences.get({ key: 'onboardingContent' });
    if (data?.value) {
      const contents: Content[] = JSON.parse(data?.value ?? '');
      this._onboardingContent.next(contents);
      return contents;
    }
    return [];
  }

  fetchContentFromApi(contentArea: ContentArea) {
    return this.GetDataWithFilter<Content[]>(
      { contentArea },
      `${this.baseUrl}/get-contents`
    ).pipe(
      map((res) => {
        let newContent: AllContents[] = this._allContents.value;
        let index = newContent.findIndex((x) => x.contentArea === contentArea);
        index >= 0
          ? (newContent[index].contents = res.payload)
          : newContent.push({ contentArea, contents: res.payload });
        this._allContents.next(newContent);
        if (contentArea == ContentArea.Onboarding) {
          this.saveContent(res.payload);
        }
        if (contentArea == ContentArea.NewFeatures) {
          this._whatsNew.next(res.payload);
        }
        return res.payload;
      })
    );
  }
  saveContent(contents: Content[]) {
    this._onboardingContent.next(contents);
    Preferences.set({
      key: 'onboardingContent',
      value: JSON.stringify(contents),
    });
  }
}
