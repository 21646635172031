import { Injectable } from '@angular/core';
import { BehaviorSubject, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/Shared/Services/Api.service';
import {
  DeliveryAddress,
  DropOffOptions,
  FormattedCity,
  Locations,
  UserAddress,
} from './models/address.model';
import { Address } from 'cluster';
import { DeliveryOptionsEnum } from '../checkout/models/checkout.model';
import { DefaultAddressKeys } from './models/addressKeys';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends ApiService {
  baseUrl = `${environment.orderUrl}`;
  private _address = new BehaviorSubject<UserAddress | null>(null);
  private _currentAddress = new BehaviorSubject<UserAddress | null>(null);
  private _addresses = new BehaviorSubject<UserAddress[]>([]);
  private _deliveryType = new BehaviorSubject<DeliveryOptionsEnum | null>(
    DeliveryOptionsEnum.Delivery
  );
  private _dropOffOptions = new BehaviorSubject<DropOffOptions[]>([]);
  address$ = this._address.asObservable();
  currentAddress$ = this._currentAddress.asObservable();
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  setAddress(address: UserAddress) {
    localStorage.setItem('address', JSON.stringify(address));
    this._address.next(address);
  }

  setCurrentLocation(address: UserAddress) {
    this._currentAddress.next(address);
  }
  get userAddress$() {
    let address = this._address.value;
    if (address) return of(this._address.value);
    const defaultAddress = localStorage.getItem(
      DefaultAddressKeys.DefaultAddress
    );
    if (defaultAddress) {
      address = JSON.parse(defaultAddress);
    } else {
      let addressJSON = localStorage.getItem('address');
      if (addressJSON) {
        address = JSON.parse(addressJSON);
      }
    }

    this._address.next(address);
    return of(this._address.value);
  }

  getAddresses() {
    let addresses = this._addresses.value;
    if (addresses && addresses.length) return of(addresses);
    return this.GetDataWithFilter<UserAddress[]>(
      { page: 1, pageSize: 10 },
      `${this.baseUrl}/User/get-addresses`
    ).pipe(
      map((resData) => {
        return resData.payload;
      }),
      tap((addresses) => {
        this._addresses.next(addresses);
      })
    );
  }

  private fetchDropOffOptions() {
    return this.post<DropOffOptions[]>(
      {},
      `${this.baseUrl}/Lookup/get-drop-off-options`
    ).pipe(
      map((resData) => {
        this._dropOffOptions.next(resData.payload);
        return resData.payload;
      })
    );
  }

  getDropOffOptions(refresh = false) {
    let value = this._dropOffOptions.value;
    if (value && value.length > 0 && !refresh) return of(value);
    return this.fetchDropOffOptions();
  }

  createAddress(address: UserAddress) {
    return this.post<boolean>(
      address,
      `${this.baseUrl}/User/create-address`
    ).pipe(
      tap((res) => {
        this.setAddress(address);
      })
    );
  }

  updateAddress(address: UserAddress) {
    return this.post<boolean>(
      address,
      `${this.baseUrl}/User/update-address`
    ).pipe(
      tap((res) => {
        this.setAddress(address);
      })
    );
  }
}
