import { Component, Input, OnInit } from "@angular/core";
import { ModalController, ToastController } from "@ionic/angular";
import { Order } from "src/app/stores/models/orders.model";
import { Clipboard } from '@capacitor/clipboard';

@Component({
    templateUrl:'../views/transfer-info.html',
    styleUrls:['../styles/transfer-info.scss']
})

export class TransferInfoPage implements OnInit{

    @Input() order?: Order;
    @Input() amount = 0;

    constructor(
    private clipboard: Clipboard,
    private toastController: ToastController,
    private modalCtrl: ModalController
    ) {}

    ngOnInit(): void {
      }

      dismiss() {
        this.modalCtrl.dismiss();
      }

      async copyText(text: string) {
        await Clipboard.write({
          string: text
        });
        return this.presentToast();
      }

      async presentToast() {
        const toast = await this.toastController.create({
          message: "Copied to clipboard",
          duration: 2000,
        });
        toast.present();
      }

      submit() {
        this.modalCtrl.dismiss({}, 'confirm');
      }

}
