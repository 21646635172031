import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { BeneficiaryTypes } from "../models/beneficiarymodel";
import { AllTransationStatus } from "../models/enums";

@Component({
    templateUrl:'../views/transaction-status.html',
    styleUrls:['../styles/transaction-status.scss']
})
export class TransactionStatusPage implements OnInit{
    @Input() reference = '';
    @Input() message = '';
    @Input() status!: number;
    @Input() transactionName!: string;
    @Input() amount!: number;
    @Input() surCharge!: number;
    @Input() transactionType: BeneficiaryTypes = BeneficiaryTypes.Bills;
    allTransactionStatus = AllTransationStatus;
    beneficiaryTypes = BeneficiaryTypes;

    constructor(private modalCtrl: ModalController){}

      ngOnInit(): void {

    }

    dismiss() {
        this.modalCtrl.dismiss();
    }
}
