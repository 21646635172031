import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'encode'
})

export class EncodePipe implements PipeTransform {
    transform(text: string) {
      return encodeURI(text);
    }
}
