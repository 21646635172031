<ion-header>
  <ion-toolbar [color]="'light'">
      <ion-buttons slot="primary">
          <ion-button [color]="'primary'" size="small" (click)="dismiss()">
              <ion-icon slot="icon-only" class="search-icon" name="close-outline"></ion-icon>
          </ion-button>
      </ion-buttons>
      <ion-title [color]="'primary'" class="ion-text-start">{{title}}</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid class="ion-padding-horizontal">
        <ion-list lines="full">
          <ion-item *ngFor="let item of items" button="true" class="info-item">
            <ion-icon *ngIf="item.icon" slot="start" color="dark" class="ion-margin-end" [name]="item.icon"></ion-icon>
            <div>
              <ion-label class="info-label">{{item.label}}</ion-label>
              <ion-card-subtitle *ngIf="item.description"class="mt-sm info-subtitle">{{item.description}}</ion-card-subtitle>
              <!-- <ion-card-subtitle class="order-subtitle">{{ '2010-1-14' | date }} &#x2022; Completed</ion-card-subtitle> -->
            </div>
            <ion-label *ngIf="item.value" slot="end" class="info-value ion-margin-start">{{ item.currencyValue ? ( item.value |  currency : '&#8358;') : item.numericValue ? ( item.value | number): item.value }}</ion-label>
          </ion-item>
        </ion-list>
      <!-- </ion-col>
    </ion-row> -->
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border ion-padding-horizontal ion-padding-bottom">
  <ion-button expand="full" shape="round" class="btn-md ion-text-capitalize" (click)="dismiss()">Got It</ion-button>
</ion-footer>
