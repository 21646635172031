import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddRating } from 'src/app/orders/models/rating.model';
import { RateOrderPage } from 'src/app/orders/pages/rate-order.page';
import { Order } from 'src/app/stores/models/orders.model';

@Component({
  selector: 'app-rating-alert',
  templateUrl: '../views/rating-alert.html',
  styleUrls: ['../styles/rating-alert.scss'],
})
export class RatingAlertComponent implements OnInit {
  @Input() order!: Order;
  @Input() currentRating: number | undefined = 0;
  @Output() next = new EventEmitter<boolean>();
  @Input() updateStoreRating!: (
    part: Partial<AddRating>,
    isFormValid: boolean
  ) => void;

  ratings: {id: number}[] = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    }
  ]
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
  }

  setRating(rating: number) {
    this.currentRating = rating;
    this.modalCtrl.dismiss();
    this.rateOrder()
  }

  async rateOrder() {
    if (this.order?.isReviewed) return;
    const modal = await this.modalCtrl.create({
      component: RateOrderPage,
      componentProps: {
        order: this.order,
        storeRating: this.currentRating
      },
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
    }
  }
}
