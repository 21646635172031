<div class="flex-column bg-light ion-padding ion-text-center">
  <ion-row class="flex-column ion-align-items-center">
    <ion-icon name="location" class="location-icon d-inline-block ion-margin-bottom"></ion-icon>
    <ion-text class="ion-text-center heading ion-text-wrap ion-no-padding fw-bold mt-sm">Sorry, We don't deliver to your location</ion-text>
    <ion-card-subtitle class="font-sm ion-margin-bottom ion-text-wrap">We're expanding quickly, so do check back soon!</ion-card-subtitle>
    <!-- <ion-button class="ion-text-capitalize mt-sm ion-no-margin contact-btn" shape="round" expand="full">
      <ion-label>
        <ion-icon name="headset"></ion-icon>
        <span> Contact Support</span>
      </ion-label>
    </ion-button> -->
  </ion-row>
</div>
