import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductCategory } from 'src/app/products/models/products.model';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-category-stores',
  styleUrls: ['../styles/category-stores.scss'],
  templateUrl: '../views/category-stores.html',
})
export class CategoryStoresComponent implements OnDestroy, OnChanges {
  private unsubscribe: Subscription[] = [];
  categories: ProductCategory[] = [];
  @Input() city: string | undefined;
  constructor(private storeService: StoreService, private router: Router) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes['city']) {
      this.getCategoryStores();
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  getCategoryStores() {
    if (this.city) {
      const storesSub = this.storeService
        .getCategoryStores(this.city)
        .subscribe({
          next: (res) => {
            this.categories = res;
          },
        });
      this.unsubscribe.push(storesSub);
    }
  }

  selectCategory(category: ProductCategory) {
    this.router.navigate(['/', 'products'], {
      queryParams: {
        storeId: category.storeId,
        categoryId: category.id,
      },
    });
  }
}
