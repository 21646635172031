import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  constructor(private platform: Platform) {}
  isMobile() {
    return (
      !(this.platform.is('mobile') && !this.platform.is('hybrid')) ||
      this.platform.is('desktop')
    );
  }
  getConfig(): OpenIdConfiguration {
    const config: OpenIdConfiguration = {
      authority: environment.authority,
      clientId: environment.clientId,
      scope: environment.scopes,
      responseType: 'code',
      redirectUrl: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      silentRenew: true,
      useRefreshToken: true,
      ignoreNonceAfterRefresh: true,
      triggerRefreshWhenIdTokenExpired: false,
      autoUserInfo: true,
      logLevel: LogLevel.Debug,
    };

    if (this.isMobile()) {
      config.redirectUrl = 'com.getnoddit.shop://';
      config.postLogoutRedirectUri = 'com.getnoddit.shop://';
    }
    return config;
  }
}
