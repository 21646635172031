<div class="ion-padding-top content px-sm">
  <drag-scroll class="categories-scroll">
      <div (click)="selectCategory(category)" drag-scroll-item class="store-categories" *ngFor="let category of categories">
          <ion-img class="pointer" [src]="category.imageUrl"></ion-img>
          <div class="mb-sm">
            <ion-label class="category-name truncate-2" color="dark">{{category.name}}</ion-label>
          </div>
          <ion-icon class="detail-icon" [name]="'chevron-forward-circle-outline'"></ion-icon>
      </div>
  </drag-scroll>
</div>
