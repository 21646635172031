import { Injectable, NgZone } from '@angular/core';
import { ApiService } from './Api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NativePlatformService } from './nativePlatform.service';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  RegistrationError,
  Token,
} from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import {
  Apps,
  DeviceTypes,
  NotificationData,
  NotificationTypes,
} from '../models/device-types';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class PushNotificationMessagingService extends ApiService {
  token = null;
  deviceId: string | null = null;
  deviceType: DeviceTypes = DeviceTypes.Android;
  deviceName: string | null | undefined = null;
  notificationType = NotificationTypes;
  constructor(
    httpClient: HttpClient,
    private _ngZone: NgZone,
    private platForm: Platform,
    private platFormService: NativePlatformService,
    private router: Router
  ) {
    super(httpClient);
    this.platFormService.getDeviceId().then((deviceId) => {
      this.deviceId = deviceId;
    });
    this.platFormService.getPlatform().then((res) => {
      if (res === 'android') {
        this.deviceType = DeviceTypes.Android;
      } else if (res === 'ios') {
        this.deviceType = DeviceTypes.IOS;
      } else {
        this.deviceType = DeviceTypes.Web;
      }
    });
    this.platFormService.getName().then((device) => {
      this.deviceName = device;
    });
  }

  requestPermission() {
    this.platForm.ready().then((result) => {
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          this.registerDevice();
          this.deviceErrorRegistration();
        } else {
          // Show some error
        }
      });
    });
  }

  registerDevice() {
    PushNotifications.addListener('registration', (token: Token) => {
      this._ngZone.runOutsideAngular(() => {
        this.RegisterDeviceForPushNotification(token.value).subscribe(
          () => {},
          () => {}
        );
      });
    });
  }

  deviceErrorRegistration() {
    PushNotifications.addListener(
      'registrationError',
      (error: RegistrationError) => {}
    );
  }
  RegisterDeviceForPushNotification(token: string) {
    return this.post(
      {
        deviceId: this.deviceId,
        token: token,
        app: Apps.Ecommerce,
        deviceType: this.deviceType,
        device: this.deviceName,
      },
      environment.PushNotificationUrl
    );
  }
  onNotificationReceived() {
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
      }
    );
  }
  onNotificationClicked() {
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        if (notification.notification?.data) {
          var data = notification.notification.data as NotificationData;
          if (data.type) {
            if (data.type == this.notificationType.orderDetails) {
              this.router.navigate(['/orders/details', data.id]);
              return;
            }
            if (data.type == this.notificationType.orderReview) {
              this.router.navigate(['/orders/details', data.id], {
                queryParams: { isReview: true },
              });
              return;
            }
          } else {
            if (data.id && data.link) {
              const dataLinkParts = data.link.split('/');
              const nonEmptyParts = dataLinkParts.filter(part => part.trim() !== '');
              const navigateArgs = ['/', ...nonEmptyParts, +data.id];
              this.router.navigate(navigateArgs);
              return;
            }
            if (data.link) {
              this.router.navigateByUrl(data.link);
              return;
            }
          }
        }
      }
    );
  }
}
