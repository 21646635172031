import { GoogleMapsAPIWrapper } from '@agm/core';
import {
  Directive,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
declare var google: any;

@Directive({
  selector: 'sebm-google-map-directions',
})
export class DirectionsMapDirective implements OnChanges {
  @Input() origin: any;
  @Input() destination: any;
  @Input() wayPoints: any[] = [];
  @Input() directionsDisplay: any;
  @Output() durationDate: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() arrived: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() mapClicked: EventEmitter<any> = new EventEmitter<any>();
  private bikeMarker: any;
  private sourceMarker: any;
  private destinationMarker: any;
  private routeSteps: any[] = []; // Store route steps

  constructor(private gmapsApi: GoogleMapsAPIWrapper) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['origin'] || changes['destination']) {
      this.gmapsApi.getNativeMap().then((map) => {
        if (this.origin.lat == 0) return;

        const sourcePosition = new google.maps.LatLng(
          this.origin.lat,
          this.origin.lng
        );

        const directionsService = new google.maps.DirectionsService();

        if (!this.directionsDisplay) {
          this.directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true, // Suppress default A and B markers
          });
        }

        this.directionsDisplay.setMap(map);

        google.maps.event.addListener(map, 'click', (event: any) => {
          const clickedLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          //  console.log('Map clicked at:', clickedLatLng.toString());
          this.mapClicked.emit(clickedLatLng); // Emit the click position
        });
        // Remove previous markers
        if (this.bikeMarker) {
          this.bikeMarker.setMap(null);
        }
        if (this.sourceMarker) {
          this.sourceMarker.setMap(null);
        }
        if (this.destinationMarker) {
          this.destinationMarker.setMap(null);
        }

        // Reset route steps
        this.routeSteps = [];

        // Add custom source marker
        this.sourceMarker = new google.maps.Marker({
          map: map,
          position: sourcePosition,
          icon: {
            url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
            scaledSize: new google.maps.Size(30, 30),
          },
        });

        // Add custom bike marker
        this.bikeMarker = new google.maps.Marker({
          map: map,
          position: sourcePosition,
          animation: google.maps.Animation.DROP,
          icon: {
            url: 'assets/images/bikerider.png',
            scaledSize: new google.maps.Size(30, 30),
          },
        });

        directionsService.route(
          {
            origin: this.origin,
            destination: this.destination,
            optimizeWaypoints: false,
            travelMode: 'DRIVING',
          },
          (response: any, status: any) => {
            if (status === 'OK') {
              this.directionsDisplay.setDirections(response);

              // Extract duration from the route response
              const route = response.routes[0];
              const duration = route.legs.reduce((total: number, leg: any) => {
                return total + leg.duration.value;
              }, 0);

              // Convert duration to Date object
              const currentTime = new Date();
              const estimatedArrivalTime = new Date(
                currentTime.getTime() + duration * 1000
              );
              this.durationDate.emit(estimatedArrivalTime);

              // Store route steps for movement
              route.legs.forEach((leg: any) => {
                leg.steps.forEach((step: any) => {
                  this.routeSteps.push(step);
                });
              });

              // Add custom destination marker at the exact end of the route
              const legs = route.legs;
              const lastLeg = legs[legs.length - 1];
              const lastStep = lastLeg.steps[lastLeg.steps.length - 1];
              const endLocation = lastStep.end_location;

              this.destinationMarker = new google.maps.Marker({
                map: map,
                position: endLocation,
                icon: {
                  url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                  scaledSize: new google.maps.Size(30, 30),
                },
              });

              // Check if source marker exceeds the last leg's end location
              google.maps.event.addListener(
                this.sourceMarker,
                'position_changed',
                () => {
                  const currentPos = this.sourceMarker.getPosition();
                  if (
                    google.maps.geometry.spherical.computeDistanceBetween(
                      currentPos,
                      endLocation
                    ) > 0
                  ) {
                    // Limit source marker's movement
                    this.sourceMarker.setPosition(endLocation);
                  }
                }
              );

              // Check if source marker reaches the last leg's end location
              google.maps.event.addListener(
                this.bikeMarker,
                'position_changed',
                () => {
                  const currentPos = this.bikeMarker.getPosition();
                  if (currentPos.equals(endLocation)) {
                    this.arrived.emit(true);
                  }
                }
              );
            } else {
              window.alert('Directions request failed due to ' + status);
            }
          }
        );
      });
    }
  }
}
