import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OrderService } from "src/app/services/order.service";
import { ApplyDiscount, Discount } from "src/app/checkout/models/checkout.model";
import { Subscription } from "rxjs";

@Component({
    templateUrl:'../views/promotions.html',
    // styleUrls:['../styles/transfer-info.scss']
})

export class PromotionsPage implements OnInit, OnDestroy {
  @Input() deliveryFee = 0;
  @Input() orderTotal = 0;
  @Input() discount?: Discount;
  form?: FormGroup;
  unsubscribe: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      code: [this.discount?.code]
    });
    const fmSbc = this.form?.valueChanges.subscribe(()=> {
      this.discount = undefined;
    });
    if (fmSbc) this.unsubscribe.push(fmSbc);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  get applyDiscount(): ApplyDiscount {
    return {
      deliveryFee: this.deliveryFee,
      orderTotal: this.orderTotal,
      promoCode: this.form?.get('code')?.value
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  calculateDiscount() {
    console.log(this.applyDiscount);

    const discountSub = this.orderService.applyDiscount(this.applyDiscount).subscribe({
      next: res=> {
        this.discount = res.payload;
      }
    });
    this.unsubscribe.push(discountSub);
  }

  submit() {
    this.modalCtrl.dismiss(this.discount, 'confirm');
  }

}
