<ion-header class="ion-no-border">
  <ion-toolbar [color]="'light'">
      <ion-buttons slot="start">
          <ion-button [color]="'primary'" size="small" (click)="dismiss()">
              <ion-icon slot="icon-only" class="search-icon" name="arrow-back-outline"></ion-icon>
          </ion-button>
      </ion-buttons>
      <ion-title [color]="'primary'" class="ion-text-start">Promotions</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid *ngIf="form" [formGroup]="form">

    <ion-row>
        <ion-col>
          <app-text-input
            [label]="'Promo Code'"
            [type]="'text'"
            formControlName="code">
          </app-text-input>
        </ion-col>
    </ion-row>

    <ion-row *ngIf="discount">
      <ion-col class="ion-margin-bottom">
        <ion-label class="font-sm">Discount({{discount.name}}): - {{ discount.amount | currency: '&#8358;' }}</ion-label>
      </ion-col>
    </ion-row>

    <ion-row>
        <ion-col>
            <ion-button *ngIf="discount; else discountBtn;" [disabled]="!form.valid" fill="outline" expand="full" shape="round" class="btn-md ion-no-margin ion-text-capitalize" (click)="submit()">Done</ion-button>
            <ng-template #discountBtn>
              <ion-button [disabled]="!form.valid" expand="full" shape="round" class="btn-md ion-no-margin ion-text-capitalize" (click)="calculateDiscount()">Apply Discount</ion-button>
            </ng-template>
        </ion-col>
    </ion-row>

  </ion-grid>
</ion-content>
