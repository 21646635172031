import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NativePlatformService {
  private _isBiometricDevice = new BehaviorSubject<boolean>(false);
  biometricDevice$ = this._isBiometricDevice.asObservable();

  constructor(private platform: Platform) {}

  isNativeDevice() {
    return this.platform.is('cordova');
  }

  async getDeviceId() {
    const id = await Device.getId();
    return id.identifier;
  }

  async getModel() {
    const info = await Device.getInfo();
    return info.model;
  }

  async getPlatform() {
    const info = await Device.getInfo();
    return info.platform;
  }

  async getName() {
    const info = await Device.getInfo();
    return info.name;
  }

  async getVersion() {
    const info = await Device.getInfo();
    return info.osVersion;
  }
}
