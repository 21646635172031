<ion-content>
  <ion-grid *ngIf="form" [formGroup]="form" class="ion-margin-horizontal h-100 d-flex flex-column ion-align-items-stretch ion-justify-content-between">
    <div formArrayName="products">
      <ion-row>
        <ion-col class="ion-text-center">
          <ion-avatar class="d-inline-block">
            <img alt="Silhouette of a person's head" class="h-100 w-100" [src]="order.store.storeLogo" />
          </ion-avatar>
        </ion-col>
      </ion-row>
      <ion-row class="mt-sm">
        <ion-col class="ion-text-center">
          <ion-label class="ion-no-padding title">How was {{order.store.storeName}}?</ion-label>
        </ion-col>
      </ion-row>
      <ion-row class="mt-sm">
        <ion-col class="ion-text-center">
          <ion-card-subtitle>Let the shop know your thoughts</ion-card-subtitle>
        </ion-col>
      </ion-row>

      <ion-row [formGroupName]="i" *ngFor="let item of order.orderItems; let i = index;">
        <ion-col class="ion-no-padding ion-padding-vertical">
          <ion-item class="w-100 ion-no-padding product-item" lines="none">
            <ion-label>{{item.product}}</ion-label>
            <ion-button (click)="rateProduct(i, false)" [ngClass]="{'active': getProductControl(i).value?.like === false }" color="primary" [fill]="getProductControl(i).value?.like === false ? 'solid': 'outline'" shape="round" slot="end" class="ion-text-capitalize mr-xs rating-btn ion-no-margin">
              <ion-icon size="small" class="rating-icon" name="thumbs-down" slot="icon-only"></ion-icon>
            </ion-button>
            <ion-button (click)="rateProduct(i, true)" [ngClass]="{'active': getProductControl(i).value?.like === true }" color="primary" [fill]="getProductControl(i).value?.like === true ? 'solid': 'outline'" shape="round" slot="end" class="ion-text-capitalize rating-btn ion-no-margin">
              <ion-icon size="small" class="rating-icon" name="thumbs-up" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-item>
          <ion-row *ngIf="(getProductControl(i).value?.like !== undefined) && getProductControl(i).value?.like !== null" class="mt-sm gap-sm ion-justify-content-start ion-margin-end">
            <ion-button (click)="productFeedback(i, feedback)" [fill]="getProductControl(i).value?.feedback === feedback ? 'solid': 'outline'" *ngFor="let feedback of (getProductControl(i).value?.like === false ? feedbackNegative: feedbackPositive)" class="ion-text-capitalize ion-no-margin">{{feedback.name}}</ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>
    <div>
      <ion-button [disabled]="!formChanged" (click)="submit()" class="ion-margin-vertical ion-text-capitalize btn-md" shape="round" expand="block">Continue</ion-button>
    </div>
  </ion-grid>
</ion-content>
