import { Injectable } from '@angular/core';
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update';
import { Platform } from '@ionic/angular';
import { ConfigService } from './auth/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  constructor(private platForm: Platform, private config: ConfigService) {}
  updateApp() {
    if (this.config.isMobile()) {
      this.performImmediateUpdate();
    }
  }
  getCurrentVersion() {
    AppUpdate.getAppUpdateInfo()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return null;
      });
  }

  getAvailableAppVersion() {
    let appVersion: string | null = null;
    AppUpdate.getAppUpdateInfo()
      .then((result) => {
        appVersion = result.availableVersion;
      })
      .catch((error) => {
        return null;
      });
    return appVersion;
  }
  openStore() {
    if(this.platForm.is('ios')){
    AppUpdate.openAppStore().then((res) => {});
    }
  }

  performImmediateUpdate() {
    AppUpdate.getAppUpdateInfo().then((result) => {
      if (
        result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE
      ) {
        return;
      }
      if (result.immediateUpdateAllowed) {
        AppUpdate.performImmediateUpdate().then().catch();
      }
      else {
        this.openStore();
      }
    });
  }

  startFlexibleUpdate() {
    AppUpdate.getAppUpdateInfo()
      .then((result) => {
        if (
          result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE
        ) {
          return;
        }
        if (result.flexibleUpdateAllowed) {
          AppUpdate.startFlexibleUpdate()
            .then(() => {
              this.completeFlexibleUpdate();
            })
            .catch();
        }
      })
      .catch();
  }

  completeFlexibleUpdate() {
    AppUpdate.completeFlexibleUpdate().then().catch();
  }
}
