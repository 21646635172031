<div *ngIf="products$ | async as allProducts">
  <div *ngIf="allProducts.length > 0">
    <ion-row class="ion-align-items-center ion-padding-horizontal ion-justify-content-between">
      <ion-title class="ion-no-padding">Popular</ion-title>
      <ion-label [routerLink]="['/', 'products', 'popular']" color="primary">See All</ion-label>
    </ion-row>
    <div class="mt-sm content px-sm">
      <drag-scroll class="categories-scroll">
          <div (click)="selectProduct(product)" drag-scroll-item class="store-categories" *ngFor="let product of allProducts">
            <ion-row class="ion-align-items-center h-100 ion-justify-content-between flex-column">
              <div class="ion-margin-bottom">
                <ion-label class="category-name truncate-2" color="dark">{{product.name}}</ion-label>
                <ion-card-subtitle *ngIf="product.category.name" class="subtitle truncate-1">{{product.category.name}}</ion-card-subtitle>
              </div>
              <ion-img class="pointer" [src]="(product.mediaAssets | sort: 'isDefault')[0].url || storeService.defaultProductImage"></ion-img>
              <ion-row class="ion-justify-content-between ion-align-self-stretch">
                <ion-text color="primary" class="ion-no-padding amount ion-align-self-center">
                    {{ product.price |  currency : '&#8358;'}} &nbsp;
                </ion-text>
                <ion-button shape="round" class="add-btn ion-no-margin">
                  <ion-icon slot="icon-only" name="add"></ion-icon>
                </ion-button>
              </ion-row>
            </ion-row>
          </div>
      </drag-scroll>
    </div>
  </div>
</div>
