export interface Advert { 
    image: string; 
    link: string; 
    type: number;
}

export interface Content {
    id: number;
    title: string;
    description: string;
    clientId: string;
    contentAreaValue: ContentAreaValue;
    link: string;
    hasLink: boolean;
    isExternalLink: boolean;
    mediaAssets: MediaAssets[];
}

export interface AllContents {
    contentArea: ContentArea;
    contents: Content[];
}

export enum ContentArea {
    Dashboard = 1,
    Onboarding = 2,
    Page = 3,
    NewFeatures= 4
}

export interface ContentAreaValue {
  id: number;
  name: string;
}

export interface MediaAssets {
    name: string;
    url: string;
    extension: string;
    isDefault: boolean;
    isExternalStorage: boolean;
    mediaTypeValue: MediaTypeValue
}

export interface MediaTypeValue {
    id: number;
    name: string;
}