import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import {
  retry,
  catchError,
  tap,
  finalize,
  take,
  timeout,
} from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { SweetAlertService } from '../Services/swal.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class HttpRequestInterceptor implements HttpInterceptor {
  message = '';
  private swalService: SweetAlertService;
  private oidcSecurityService: OidcSecurityService;
  private authService: AuthenticationService;
  constructor(private injector: Injector, private spinner: NgxSpinnerService) {
    this.swalService = this.injector.get(SweetAlertService);
    this.oidcSecurityService = this.injector.get(OidcSecurityService);
    this.authService = this.injector.get(AuthenticationService);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.presentSpinner(request.url);
    if (this.oidcSecurityService !== undefined) {
      this.oidcSecurityService
        .getAccessToken()
        .pipe(take(1))
        .subscribe((accessToken) => {
          let tokenValue = 'Bearer ' + accessToken;
          request = request.clone({
            setHeaders: { Authorization: tokenValue },
          });
        });
    }
    return next.handle(request).pipe(
      retry(0),
      timeout(300000),
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if (evt.body) {
            if (evt.body.description || evt.body?.message) {
              this.presentAlert(
                'success',
                evt.body.description ?? evt.body?.message,
                request.url
              );
            }
          }
        }
        return evt;
      }),
      catchError((error: HttpErrorResponse) => {
        this.spinner.hide();
        if (error instanceof TimeoutError) {
          return throwError(
            this.presentAlert(
              'error',
              'We Could not connect to the service because of a timeout',
              request.url
            )
          );
        } else {
          let errorMessage = '';
          if (error?.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
            // to a toast

            return throwError(
              this.presentAlert('error', errorMessage, request.url)
            );
          } else {
            if ([401, 403].indexOf(error?.status) !== -1) {
              this.authService.logout();
            }
            // if (error?.status === 500) return throwError(this.isServerError());
            if (error?.error == null) {
              return throwError(
                this.presentAlert(
                  'error',
                  'could not get data, please try again',
                  request.url
                )
              );
            }
            if (error.error.error != undefined) {
              this.presentAlert(
                'error',
                error.error.error_description,
                request.url
              );
              return throwError(error.error);
            }
            if (error?.error?.description == 'Invalid Token') {
              return throwError(error.error);
            }
            errorMessage =
              error.error.message ||
              'Could not reach the service at the moment, please try again';
          }
          errorMessage = errorMessage;
          this.presentAlert('error', errorMessage, request.url);
          return throwError(error.error);
        }
      }),
      finalize(() => {
        this.spinner.hide();
      })
    );
  }

  presentAlert(
    type: 'success' | 'error',
    message: string,
    requestUrl: string,
    useForce = false
  ) {
    let exemptedAlertRoutes: string[] = [
      `${environment.orderUrl}/User/update-address`,
      `${environment.orderUrl}/User/create-address`,
      `${environment.PushNotificationUrl}`,
      `${environment.shopUrl}/search-stores`,
      `${environment.authority}/connect/token`,
      `${environment.orderUrl}/pending-order-review`,
      `${environment.hubUrl}`,
      `${environment.SiteVisitUrl}`,
      `${environment.shopUrl}/add-store-visits`,
    ];

    if (
      !exemptedAlertRoutes.some((x) =>
        requestUrl.toLowerCase().includes(x.toLowerCase())
      ) ||
      useForce
    )
      return this.swalService.showAlert(
        type,
        type == 'success' ? 'Success' : 'Error',
        message,
        false
      );
    return;
  }

  isServerError() {
    return this.presentAlert(
      'error',
      'could not get data, please try again',
      '',
      true
    );
  }

  presentSpinner(requestUrl: string, useForce?: boolean) {
    let exemptedLoadingRoutes: string[] = [
      `pending-order-review`,
      `search-stores`,
      `favorite-stores`,
      `popular-products`,
      `featured-stores`,
      `add-favorite-store`,
      `add-site-visits`,
      `add-store-visits`,
    ];
    if (
      !exemptedLoadingRoutes.some((x) =>
        requestUrl.toLowerCase().includes(x.toLowerCase())
      ) ||
      useForce
    )
      return this.spinner.show();
    return;
  }
}
