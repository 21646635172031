import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, mergeMap } from 'rxjs';
import { AddressService } from './address/address.service';
import { UserAddress } from './address/models/address.model';
import { AuthenticationService } from './auth/services/authentication.service';
import { BasketService } from './basket/basket.service';
import { SwUpdate } from '@angular/service-worker';
import {
  IonRouterOutlet,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { PushNotificationMessagingService } from './Shared/Services/push-notification-service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { environment } from 'src/environments/environment';
import { DataService } from './Shared/Services/data-service';
import { AuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { asyncScheduler } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { url } from 'inspector';
import { ConfigService } from './auth/services/config.service';
import { AppUpdateService } from './app.update.service';
import { PackageService } from './package/package.service';
import * as Sentry from '@sentry/angular-ivy';
import { HTMLIonOverlayElement } from '@ionic/core';
import { AdvertService } from './Shared/Services/advert.service';
import { ContentArea } from './Shared/models/advert.model';
import { WhatsNewPage } from './Shared/components/whats-new.page';
import { MessagingService } from './Shared/Services/messaging.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  unsubscribe: Subscription[] = [];
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet:
    | IonRouterOutlet
    | undefined;
  isWhatsNewActive = false;
  constructor(
    private basketService: BasketService,
    private addressService: AddressService,
    private authService: AuthenticationService,
    private zone: NgZone,
    private router: Router,
    private swsUpdate: SwUpdate,
    private toastrController: ToastController,
    private dataService: DataService,
    private configService: ConfigService,
    private pushNotificationService: PushNotificationMessagingService,
    private appUpdate: AppUpdateService,
    private platform: Platform,
    private packageService: PackageService,
    private advertService: AdvertService,
    private modalCtrl: ModalController //private messagingService: MessagingService
  ) {
    this.handleMobileAuthenticationCallBack();
    this.handlePayWithCardBrowserCallBackUrlOpen();
    this.initializeDeepLinks();
    this.exitApp();
  }

  ngOnInit(): void {
    this.appUpdate.updateApp();
    if (this.configService.isMobile()) {
      this.authService.currentUser$.subscribe((user) => {
        if (user.isAuthenticated) {
          this.pushNotificationService.requestPermission();
        }
      });

      this.pushNotificationService.onNotificationReceived();
      this.pushNotificationService.onNotificationClicked();
    }

    // if (!this.configService.isMobile()) {
    //   this.authService.currentUser$.subscribe((user) => {
    //     if (user.isAuthenticated) {
    //     }
    //     this.messagingService.requestPermission().then((token) => {
    //       this.pushNotificationService.RegisterDeviceForPushNotification(token);
    //     });
    //   });

    //   //this.messagingService.receiveMessage();
    // }
    this.dataService.init();
    if (!this.configService.isMobile()) {
      this.swsUpdate.checkForUpdate().then((result) => {
        if (result) {
          this.presentToast();
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      });
    }

    this.authService.loadUserProfile();
    const userSub = this.authService.currentUser$.subscribe({
      next: (user) => {
        this.setCurrentLogScope(user);
        if (!user.isAuthenticated) return;
        this.updateBasketId(user.id, user.email);
        const returnUrl = localStorage.getItem('returnUrl');
        if (returnUrl) {
          localStorage.removeItem('returnUrl');
          if (returnUrl != environment.mobileRedirectUrl)
            this.router.navigateByUrl(returnUrl);
        }
        this.processPendingAddress();
        this.initWhatsNew();
      },
    });
    this.unsubscribe.push(userSub);
    this.initBasket();
  }

  initWhatsNew() {
    this.zone.runOutsideAngular(() => {
      this.advertService.getContent(ContentArea.NewFeatures).subscribe(() => {
        this.whatsNew();
      });
    });
  }

  whatsNew() {
    this.advertService.whatsNewContent$.subscribe({
      next: async (contents) => {
        if (!contents?.length || this.isWhatsNewActive) return;
        this.isWhatsNewActive = true;
        const modal = await this.modalCtrl.create({
          component: WhatsNewPage,
          componentProps: {
            contents: contents,
          },
        });
        modal.present();
        const { data, role } = await modal.onWillDismiss();
        this.isWhatsNewActive = false;
      },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  initBasket() {
    const basketId = localStorage.getItem('basket_id');
    if (basketId) this.basketService.getBasket(basketId);
  }

  processPendingAddress() {
    let pendingAddress: UserAddress | null;
    const pendingAddressJSON = localStorage.getItem('pendingAddress');
    if (pendingAddressJSON) {
      pendingAddress = JSON.parse(pendingAddressJSON);
      if (!pendingAddress) return;
      if (pendingAddress.id) return this.updateAddress(pendingAddress);
      else return this.createAddress(pendingAddress);
    }
  }

  createAddress(address: UserAddress) {
    const createSub = this.addressService.createAddress(address).subscribe({
      next: () => {
        localStorage.removeItem('pendingAddress');
      },
    });
    this.unsubscribe.push(createSub);
  }

  updateAddress(address: UserAddress) {
    const updateSub = this.addressService.updateAddress(address).subscribe({
      next: () => {
        localStorage.removeItem('pendingAddress');
      },
    });
    this.unsubscribe.push(updateSub);
  }

  updateBasketId(userId: string, email: string) {
    const basketSub = this.basketService.basketSource$.subscribe((basket) => {
      if (!basket || basket?.userId === userId) return;
      let updatedBasket = { ...basket };
      updatedBasket.oldBasketId = basket.userId;
      updatedBasket.userId = userId;
      updatedBasket.email = email;
      const setSub = this.basketService.setBasket(updatedBasket).subscribe({
        next: (res) => {
          localStorage.setItem('basket_id', userId);
          this.initBasket();
        },
      });
      this.unsubscribe.push(setSub);
    });
    this.unsubscribe.push(basketSub);
  }

  async presentToast() {
    const toast = await this.toastrController.create({
      message:
        'A new version of the app is available and will be updated shortly.',
      duration: 2000,
    });
    toast.present();
  }

  handleMobileAuthenticationCallBack() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        this.handleLoginCallbacks(event.url);
      });
    });
  }

  closeIOSBrowser() {
    if (Capacitor.getPlatform() === 'ios') {
      // add promise rejection handler to account for app being opened not via the in-app browser
      Browser.close().then().catch();
    }
  }

  initializeDeepLinks() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = environment.appUrl;
        const pathArray = event.url.split(domain);
        const appPath = pathArray.pop();
        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
      });
    });
  }

  handleLoginCallbacks(url: string) {
    const domain = this.configService.getConfig().redirectUrl ?? '';
    if (url.startsWith(domain)) {
      if (
        url.includes('state=') &&
        (url.includes('error=') || url.includes('code='))
      ) {
        this.authService.loadUserProfile(url);
        this.closeIOSBrowser();
      } else {
        this.closeIOSBrowser();
        // redirect to home when logging out
        this.router.navigate(['/']);
      }
    }
  }

  handlePayWithCardBrowserCallBackUrlOpen() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        if (
          event.url.startsWith(environment.CheckOutUrl) ||
          event.url.startsWith(environment.packageMobileRedirectUrl)
        ) {
          if (event.url.startsWith(environment.CheckOutUrl)) {
            const pathArray = event.url.split(environment.CheckOutUrl);
            const appPath = pathArray.pop();
            this.router.navigateByUrl(`/checkout/status${appPath}`);
          } else {
            this.packageService.reloadOrders();
            this.router.navigateByUrl(`/package`);
          }
          this.closeIOSBrowser();
        }
      });
    });
  }

  exitApp() {
    const sub = this.platform.backButton.subscribeWithPriority(-1, async () => {
      if (this.routerOutlet && !this.routerOutlet.canGoBack()) {
        if (this.router.url == '/auth/login' || this.router.url == '/auth') {
          return App.exitApp();
        } else if (this.router.url == '/stores') {
          return App.exitApp();
        } else {
          return this.router.navigate(['/', 'stores'], {
            replaceUrl: true,
          });
        }
      }

      if (this.router.url == '/stores') {
        return App.exitApp();
      }

      if (this.router.url == '/auth/login' || this.router.url == '/auth') {
        return App.exitApp();
      }
    });
  }

  setCurrentLogScope(user: any) {
    Sentry.getCurrentScope().setUser({
      email: user?.email,
      id: user?.id,
      username: user?.phone_number,
    });
  }

  closeAll() {
    // adjust selector to fit your needs
    const overlays = document.querySelectorAll(
      'ion-alert, ion-action-sheet, ion-loading, ion-modal, ion-picker, ion-popover, ion-toast'
    );
    const overlaysArr = Array.from(overlays) as HTMLIonOverlayElement[];
    overlaysArr.forEach((o) => o.dismiss());
  }
}
