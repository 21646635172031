<ion-header [translucent]="true" class="ion-no-border">
    <ion-toolbar class="bg-transparent">
      <ion-buttons mode="md" slot="end">
          <ion-icon slot="icon-only"  slot="end" class="icon-start" (click)="dismiss()" name="close-outline"></ion-icon>

      </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-slides #slides class="content-slides-new min-h-100" pager="true" [options]="slideOptions">
        <ion-slide class="content-element-2 no-shadow d-flex flex-column content-new pb-lg" *ngFor="let content of contents; let i = index;">
          <div class="container">
            <div class="content">
                <div class="content-box">
                    <img *ngIf="content.mediaAssets.length>0" [src]="(content.mediaAssets | sort: 'isDefault': false)[0]?.url" class="content-image">
                </div>
            </div>
        </div>
            <div class="d-flex gap-1 flex-column ion-justify-content-between w-100">
                <div>
                    <h2 class="ion-no-padding title ion-margin-bottom ion-text-start ion-align-self-start" [innerHtml]="content.title"></h2>
                    <ion-text #desc id="description" class="description ion-text-start ion-align-self-start" [innerHtml]="content.description"></ion-text>
                </div>

                <ion-button shape="round" color="primary" expand="full" (click)="nextSlide(i+1 >=contents.length)" class="btn-md border-1 mb-sm ion-text-capitalize">
                    {{(i+1) >= contents.length ? 'Got It': 'Next'}}
                </ion-button>
            </div>
        </ion-slide>
    </ion-slides>
</ion-content>
