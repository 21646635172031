import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";


@Component({
  templateUrl:'../views/select-schedule.component.html',
  selector : 'app-select-schedule',
  styleUrls:['../styles/select-schedule.component.scss']
})
export class SelectScheduleComponent implements OnInit {
  selectedDate!: number;
  selectedTime: any;
  scheduleTimes = [
    {
      id: 1,
      value: '10:15AM - 10:45AM'
    },
    {
      id: 2,
      value: '11:15AM - 11:45AM'
    },
    {
      id: 3,
      value: '12:15PM - 12:45PM'
    },
    {
      id: 4,
      value: '1:15PM - 1:45PM'
    },
    {
      id: 5,
      value: '2:15PM - 2:45PM'
    },
]

  @Input() notes = '';

  constructor(private modalCtrl: ModalController){}

  ngOnInit(): void {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  selectDate(date: number) {
    this.selectedDate = date;
  }

  submit() {
    this.modalCtrl.dismiss({ date: this.selectedDate, time: this.selectedTime}, 'confirm');
  }
}
