<ion-header>
  <ion-toolbar [color]="color">
    <ion-buttons slot="start">
      <ion-buttons slot="start">
        <ion-back-button [color]="color==='light' || color==='tertiary' ? 'primary': 'light'" [defaultHref]="defaultHref"></ion-back-button>
      </ion-buttons>
    </ion-buttons>
    <ion-title [color]="color==='light' || color==='tertiary' ? 'primary': 'light'" class="ion-text-center">{{title}}</ion-title>
  </ion-toolbar>
</ion-header>
