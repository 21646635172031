import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSlides, IonicSlides, ModalController } from '@ionic/angular';
import { DeliveryOptionsEnum } from 'src/app/checkout/models/checkout.model';
import { Order } from 'src/app/stores/models/orders.model';
import { AddRating, AddReview, FeedBackTypeEnum, ReviewTypeEnum } from '../models/rating.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-rate-order',
  templateUrl: '../views/rate-order.page.html',
  styleUrls: ['../styles/rate-order.page.scss'],
})
export class RateOrderPage implements OnInit, OnDestroy, AfterViewInit {
  unsubscribe: Subscription[] = [];
  @Input() order!: Order;
  @Input() storeRating = 0;
  // @ViewChild("ratingSlides") ratingSlides: any;
  deliveryOptionsEnum = DeliveryOptionsEnum;
  @ViewChild('slides') slides!: IonSlides;
  swiperModules = [IonicSlides];
  review$: BehaviorSubject<AddReview> =
    new BehaviorSubject<AddReview>({
      orderId: '',
      reviews: []
    });
  storeRating$: BehaviorSubject<AddRating> =
    new BehaviorSubject<AddRating>({
      description: '',
      rating: this.storeRating,
      feedBackTypeId: null,
      reviewType: ReviewTypeEnum.Store,
      reviewTypeId: ReviewTypeEnum[ReviewTypeEnum.Store]
  });

  driverRating$: BehaviorSubject<AddRating> =
    new BehaviorSubject<AddRating>({
      description: '',
      rating: 0,
      feedBackTypeId: FeedBackTypeEnum.Good,
      reviewType: ReviewTypeEnum.Driver,
      reviewTypeId: ReviewTypeEnum[ReviewTypeEnum.Driver]
  });

  productsRatings$: BehaviorSubject<AddRating[]> =
    new BehaviorSubject<AddRating[]>([]);

  constructor(
    private modalCtrl: ModalController,
    private orderService: OrderService
    ) {}

  ngOnInit() {
    console.log(this.storeRating);

    this.updateStoreRating({ reviewTypeId: this.order.store.storeId, rating: this.storeRating }, true);
    this.updateDriverRating({ reviewTypeId: this.order.deliveryAgent?.phoneNumber || '' }, true);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.slides.lockSwipes(true);
  }


  async previous() {
    let index = await this.getSlideIndex();
    if (index<=0) return this.modalCtrl.dismiss();
    this.slides.lockSwipes(false);
    this.slides.slidePrev();
    return this.slides.lockSwipes(true);
  }

  async skip() {
    let length = await this.slides.length();
    let currentIndex = await this.slides.getActiveIndex();
    if ((currentIndex+1)===length) return this.modalCtrl.dismiss();
    this.slides.lockSwipes(false);
    this.slides.slideNext();
    return this.slides.lockSwipes(true);
  }

  async next() {
    this.slides.lockSwipes(false);
    this.slides.slideNext();
    this.slides.lockSwipes(true);
  }

  async getSlideIndex() {
    return await this.slides.getActiveIndex();
  }

  submit() {
    let reviews: AddReview = {
      orderId: this.order.orderNumber,
      reviews: [
        ... this.storeRating$.value.rating>0 ? [this.storeRating$.value] : [],
        this.storeRating$.value,
        ...this.productsRatings$.value,
        ... this.order.deliveryMethod.id===DeliveryOptionsEnum.Delivery ? [this.driverRating$.value] : []
      ]
    }
    const reviewSub = this.orderService.addReview(reviews).subscribe(res=> {
      this.modalCtrl.dismiss();
    });
    this.unsubscribe.push(reviewSub);


  }

  initReview() {
    let review: AddReview = {
      orderId: this.order.orderNumber,
      reviews: []
    }
  }

  updateRating() {
    let ratings: AddRating[] = [
      this.storeRating$.value,
      ...this.productsRatings$.value,
      this.driverRating$.value
    ];
  }

  updateStoreRating = (
    part: Partial<AddRating>,
    isFormValid: boolean
  ) => {
    if (!isFormValid) return;
    const currentRating = this.storeRating$.value;
    const updatedRating = { ...currentRating, ...part };
    this.storeRating$.next(updatedRating);
  };

  updateProductsRating = (
    rating: AddRating[],
    isFormValid: boolean
  ) => {
    if (!isFormValid) return;
    this.productsRatings$.next(rating);
  };

  updateDriverRating = (
    part: Partial<AddRating>,
    isFormValid: boolean
  ) => {
    if (!isFormValid) return;
    const currentRating = this.driverRating$.value;
    const updatedRating = { ...currentRating, ...part };
    this.driverRating$.next(updatedRating);
  };

  addRating() {
  }

}
