import {
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-no-location-items',
  styleUrls: ['../styles/no-location-items.scss'],
  templateUrl: '../views/no-location-items.html',
})
export class NoLocationItemsComponent implements OnDestroy {
  private unsubscribe: Subscription[] = [];
  @Input() city: string | undefined;

  constructor() {}

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
