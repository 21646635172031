export interface Rating {
  description: string;
  rating: number;
  buyer: string;
  feedBack: string;
  dateCreated: string;
}

export interface AddRating {
  rating: number;
  reviewType: ReviewTypeEnum;
  feedBackTypeId: number | null;
  reviewTypeId: string;
  description: string;
}

export interface AddReview {
  orderId: string;
  reviews: AddRating[];
}

export interface ReviewTypes {
  reviewType: 1,
  feedBackTypes: FeedBackTypes[]
}

export interface FeedBackTypes {
  feedBackType: number;
  feedBacks: FeedBack[];
}

export interface FeedBack {
  id: number;
  name: string;
}

export interface RatingQuery {
  reviewTypeId: string,
  page: number,
  pageSize: number
}

export interface OrderRating {
  description: string,
  rating: number,
  feedBack: string,
  orderItems: OrderItemRating[]
}

export interface OrderItemRating {
  id: number,
  product: string,
  image: string,
  feedBack: string,
  rating: number,
  description: string
}

export enum ReviewTypeEnum {
  Store = 1,
  Product = 2,
  Driver = 3
}

export enum FeedBackTypeEnum {
  Good = 1,
  Bad = 2
}
