import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { Content, ContentArea } from '../models/advert.model';
import { AdvertService } from '../Services/advert.service';
import { IonSlides, ModalController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
@Component({
  templateUrl: '../views/whats-new.html',
  selector: 'app-whats-new',
  styleUrls: ['../styles/whats-new.scss'],
})
export class WhatsNewPage implements OnInit, OnDestroy {
  @ViewChildren('desc', { read: ElementRef })
  descriptions!: QueryList<ElementRef>;
  // @ViewChild('slides')  slides: any;
  @Input() contentArea: ContentArea = ContentArea.NewFeatures;
  @ViewChild('swiper') swiperRef: ElementRef | undefined;
  @ViewChild('slides', {static: true}) slides!: IonSlides;
  allContentArea = ContentArea;
  @Input() contents: Content[] = [];
  slideOptions = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 1,
    loop: false,
    spaceBetween: 15,
  };

  constructor(
    public advertService: AdvertService,
    private router: Router,
    private modalCtrl: ModalController
  ) {}

  ngOnInit(): void {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy(): void {}

  nextSlide(isLastSlide: boolean) {
    if (isLastSlide) {
      this.modalCtrl.dismiss().then();
      return;
    }
    this.slides.slideNext(1000);
  }

  get activeIndex() {
    return this.swiperRef?.nativeElement.swiper.activeIndex ?? 0;
  }
  
  async select(content: Content) {
    if (!content.isExternalLink) return this.router.navigateByUrl(content.link);
    await Browser.open({
      url: content.link,
      windowName: '_self',
    });
    await Browser.addListener('browserFinished', () => {
      this.dismiss();
    });
    return;
  }
}
