import { Injectable } from '@angular/core';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { DataService } from './data-service';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService implements AbstractSecurityStorage {
  constructor(private storage: DataService) {}
  count = 0;
  // public async remove(key: string) {
  //   await this.storage.remove(key);
  // }
  // public async clear() {
  //   await this.storage.clear();
  // }
  // public async read(key: string) {
  //   await this.storage.read(key);
  // }
  // public async write(key: string, value: any) {
  //   await this.storage.write(key, value);
  // }

  read(key: string) {
    return localStorage.getItem(key);
  }

  write(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
