import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { PaymentOptionEnum } from 'src/app/checkout/models/checkout.model';
import { OrderService } from 'src/app/services/order.service';
import {
  OrderStatusEnum,
  PaymentStatusEnum,
} from 'src/app/stores/models/orders.enum';
import { CompletePayment, Order } from 'src/app/stores/models/orders.model';
import { ApiResponse } from '../models/ApiResponse';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/auth/services/config.service';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-order-status',
  templateUrl: '../views/order-status.page.html',
  styleUrls: ['../styles/order-status.page.scss'],
})
export class OrderStatusPage implements OnInit, OnDestroy {
  unsubscribe: Subscription[] = [];
  order?: Order;
  paymentStatus = PaymentStatusEnum;
  orderStatus = OrderStatusEnum;
  reference: null | undefined;
  verifyCount = 0;

  constructor(
    private orderService: OrderService,
    private auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private zone: NgZone,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    const authSub = this.auth.currentUser$.subscribe((user) => {
      if (!user.isAuthenticated) return this.auth.login();
      let orderSub = this.orderService.currentOrder$.subscribe({
        next: (res) => {
          if (!res) {
            return this.initParams();
          } else {
            this.order = res;
            if (
              this.order?.paymentMethod.modeOfPayment.id ===
                PaymentOptionEnum.Transfer &&
              this.order?.paymentMethod.status.id ===
                PaymentStatusEnum.PendingDebit &&
              !this.reference
            )
              return this.initTransferValidation();
          }
          return;
        },
      });
      this.unsubscribe.push(orderSub);
    });
    this.unsubscribe.push(authSub);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  initParams() {
    this.route.queryParams.subscribe((params) => {
      this.reference = params['reference'];
      if (!this.reference && !this.order)
        return this.router.navigate(['/stores'], {
          replaceUrl: true,
        });
      if (!this.order) return this.initReferenceValidation();
    });
  }

  initReferenceValidation() {
    const verifySub = this.orderService
      .completeCardPayment(this.reference || '')
      .subscribe({
        next: (res) => {
          this.order = res.payload;
        },
      });
    this.unsubscribe.push(verifySub);
  }

  initTransferValidation() {
    let model: CompletePayment = {
      orderId: this.order?.orderNumber ?? '',
      challenge: {
        challengeType: 0,
        value: '',
        reference: this.order?.paymentMethod?.reference ?? '',
      },
    };
    this.orderService.completePayment(model).subscribe({
      next: (res) => {
        this.order = res.payload;
        if (
          this.order?.paymentMethod.status.id !== PaymentStatusEnum.PendingDebit
        )
          return;
        setTimeout(() => {
          this.verifyCount += 1;
          if (this.verifyCount >= 30) return this.viewOrder();
          return this.initTransferValidation();
        }, 10000);
      },
      error: (err: ApiResponse<Order>) => {
        if (err.payload === undefined || err.payload === null)
          return this.router.navigate(
            ['/', 'orders', 'details', this.order?.orderNumber],
            {
              replaceUrl: true,
            }
          );
        this.order = err?.payload;
        if (
          err?.payload?.paymentMethod.status.id !==
          PaymentStatusEnum.PendingDebit
        )
          return this.viewOrder();
        // {
        //   this.verifyCount+=1;
        //   if (this.verifyCount>=30) this.viewOrder();
        //   return;
        // }
        return setTimeout(() => {
          this.verifyCount += 1;
          if (this.verifyCount >= 30) this.viewOrder();
          return this.initTransferValidation();
        }, 10000);
      },
    });
  }

  viewOrder() {
    if (!this.order) return;
    this.router.navigate(['/', 'orders', 'details', this.order?.orderNumber], {
      replaceUrl: true,
    });
  }

  toStores() {
    this.router.navigate(['/', 'stores'], {
      replaceUrl: true,
    });
  }
}
