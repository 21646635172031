import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  private storageReady = new BehaviorSubject<boolean>(false);
  storageReady$ = this.storageReady.asObservable();
  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    //await this.storage.defineDriver(CordovaSQLiteDriver);
    await this.storage.create();
    this.storageReady.next(true);
  }
  public async remove(key: string): Promise<boolean> {
    await this.storage.remove(key);
    return true;
  }
  public async clear(): Promise<void> {
    await this.storage.clear();
  }
  public async read(key: string) {
    const result = await this.storage.get(key);
    return result;
  }
  public async write(key: string, value: any) {
    await this.storage.set(key, value);
    return true;
  }
}
