<ion-content>
  <ion-grid class="ion-padding-vertical h-100">
    <ion-row class="ion-align-items-center h-100 ion-justify-content-center">
      <ion-col size="12" size-sm="12" size-md="10" size-lg="8" size-xl="7" class="ion-no-padding">
        <ion-row class="ion-text-center main-row ion-justify-content-center ion-align-items-center ion-padding-horizontal">
          <ion-icon *ngIf="!order || (order?.paymentMethod?.status?.id === paymentStatus.PendingDebit); else icon" name="hourglass" class="basket-icon" color="primary"></ion-icon>
          <ng-template #icon>
            <ion-img *ngIf="order?.paymentMethod?.status?.id===paymentStatus.Successful" class="basket-img" [src]="'assets/images/basket.png'"></ion-img>
            <ion-icon *ngIf="order?.paymentMethod?.status?.id!==paymentStatus.Successful" src="assets/icon/error.svg" class="basket-icon" color="primary"></ion-icon>
          </ng-template>

          <!-- <ion-icon></ion-icon> -->
          <ion-title class="ion-margin-bottom ion-no-padding">Payment {{ !order? 'Verification' : order.status.id===orderStatus.PendingPayment ? 'Pending': order.paymentMethod.status.id===paymentStatus.Successful ? 'Successful': 'Failed' }}</ion-title>
          <div class="ion-margin-bottom">
            <ng-container *ngIf="!order || (order?.paymentMethod?.status?.id === paymentStatus.PendingDebit); else orderCancelled">
              <ion-label color="medium">
                <ion-spinner class="v-middle" color="primary" name="crescent"></ion-spinner>
                <span class="v-middle">We are verifying your transaction, ensure you have completed the payment.</span>
              </ion-label>
            </ng-container>
            <ng-template #orderCancelled>
              <ng-container *ngIf="order?.status?.id===orderStatus.Cancelled; else orderPending">
                <ion-label color="medium">Your order has been cancelled, return to the store to create a new order.</ion-label>
              </ng-container>
            </ng-template>
            <ng-template #orderPending>
              <ng-container *ngIf="order?.status?.id===orderStatus.PendingPayment; else paymentFailed">
                <ion-label color="medium">We can not verify your payment at this time, try again later.</ion-label>
              </ng-container>
            </ng-template>
            <ng-template #paymentFailed>
              <ng-container *ngIf="order?.status?.id===orderStatus.PendingPayment; else defaultInfo">
                <ion-label color="medium">We could not verify your payment, please create a new order.</ion-label>
              </ng-container>
            </ng-template>
            <ng-template #defaultInfo>
              <ion-label color="medium">Your order is being processed</ion-label>
            </ng-template>
          </div>
          <ion-button *ngIf="order" (click)="viewOrder()" class="btn-md ion-text-capitalize" shape="round">Track Order Status</ion-button>
          <ion-button fill="clear" (click)="toStores()" class="btn-md ion-text-capitalize" shape="round">Back to Stores</ion-button>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
