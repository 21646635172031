import { Injectable } from '@angular/core';
import { Notification } from '../models/notification.model'

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
    notifications = [
        new Notification(
            "You're almost done", 
            "Click here set up your pin", 
            "payments", 
            "notification"),
        new Notification(
            'Pending Transfers', 
            'Transactions reversed', 
            'payments/transfer', 
            'person')
        ]

}
