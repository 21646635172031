import { Component, Input } from "@angular/core";

@Component({
  templateUrl: "../views/header.html",
  selector: "app-header",
  // styleUrls: ["../styles/header.scss"],
})
export class HeaderPage {
  @Input() color: string = "secondary";
  @Input() title: string = '';
  @Input() sideMenu: boolean = false;
  @Input() defaultHref: string | null | undefined = "/stores";
  @Input() showImage: boolean = false;

  greet: string = "";
  constructor() {
    this.greet = this.getTime();
  }

  getTime(): string {
    const myDate = new Date();
    const hrs = myDate.getHours();
    let greet = '';
    if (hrs < 12) greet = "Good Morning";
    else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
    else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
    return greet;
  }
}
