import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../Shared/Services/Api.service';
import {
  CreatePackage,
  PackageAddress,
  PackageItemType,
  PackageOrder,
  PackageQuery,
} from './models/package.model';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, map, of } from 'rxjs';
import {
  deliveryFeeModel,
  deliveryFeeResponse,
} from './models/delivery-fee.model';

@Injectable({
  providedIn: 'root',
})
export class PackageService extends ApiService {
  baseUrl = `${environment.packageUrl}/orders`;
  reload = new BehaviorSubject<boolean>(false);
  private _addresses = new BehaviorSubject<PackageAddress[]>([]);
  private _packageItemTypes = new BehaviorSubject<PackageItemType[]>([]);
  private _fee = new BehaviorSubject<deliveryFeeResponse>({
    amount: 0,
    canDeliveryToLocation: false,
    distance: 0,
  });
  fee$ = this._fee.asObservable();
  reload$ = this.reload.asObservable();
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  reloadOrders() {
    this.reload.next(true);
  }
  getItemTypes() {
    let packageItemTypes = this._packageItemTypes.value;
    if (packageItemTypes.length) return of(packageItemTypes);
    return this.GetAll<PackageItemType[]>(
      `${this.baseUrl}/get-item-types`
    ).pipe(
      map((res) => {
        this._packageItemTypes.next(res.payload);
        return res.payload;
      })
    );
  }

  getSavedAddresses(refresh = false) {
    let addresses = this._addresses.value;
    if (!refresh && addresses.length) return of(addresses);
    return this.GetAll<PackageAddress[]>(
      `${this.baseUrl}/get-saved-addresses`
    ).pipe(
      map((res) => {
        this._addresses.next(res.payload);
        return res.payload;
      })
    );
  }

  createPackage(createPackage: CreatePackage) {
    return this.post<PackageOrder>(createPackage, `${this.baseUrl}/create`);
  }

  orderDetails(orderNumber: string) {
    return this.GetAll<PackageOrder>(
      `${this.baseUrl}/${orderNumber}/order-details`
    );
  }
  trackOrderDetails(orderNumber: string) {
    return this.GetAll<PackageOrder>(`${this.baseUrl}/${orderNumber}/track`);
  }
  getAllPackages(query: PackageQuery) {
    return this.post<PackageOrder[]>(query, `${this.baseUrl}/get-all-orders`);
  }

  calculateDeliveryFee(model: deliveryFeeModel) {
    return this.post<deliveryFeeResponse>(
      model,
      `${this.baseUrl}/calculate-delivery-fees`
    ).pipe(
      map((res) => {
        this._fee.next(res.payload);
        return res;
      })
    );
  }
}
