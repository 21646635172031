<ion-content>
  <ion-grid [formGroup]="form" *ngIf="form" class="ion-margin-horizontal h-100 d-flex flex-column ion-align-items-stretch ion-justify-content-between">
    <div>
      <ion-row>
        <ion-col class="ion-text-center">
          <ion-avatar class="d-inline-block">
            <img alt="Silhouette of a person's head" class="h-100 w-100" [src]="order.deliveryAgent?.image ?? 'https://ionicframework.com/docs/img/demos/avatar.svg'" />
          </ion-avatar>
        </ion-col>
      </ion-row>
      <ion-row class="mt-sm">
        <ion-col class="ion-text-center">
          <ion-label class="ion-no-padding title">How was {{order.deliveryAgent?.name+'\'s'}} services</ion-label>
        </ion-col>
      </ion-row>
      <ion-row class="mt-sm">
        <ion-col class="ion-text-center">
          <ion-card-subtitle>Give a thumbs up for good delivery</ion-card-subtitle>
        </ion-col>
      </ion-row>

      <ion-row class="ion-margin-top ion-align-items-center gap-sm ion-justify-content-center">
        <ion-button (click)="setRating(false)" [ngClass]="{'active': form.get('like')?.value === false }" color="primary" [fill]="form.get('like')?.value === false ? 'solid': 'outline'" shape="round" class="ion-text-capitalize mr-xs rating-btn ion-no-margin">
          <ion-icon class="rating-icon" name="thumbs-down" slot="icon-only"></ion-icon>
        </ion-button>
        <ion-button (click)="setRating(true)" [ngClass]="{'active': form.get('like')?.value === true }" color="primary" [fill]="form.get('like')?.value === true ? 'solid': 'outline'" shape="round" class="ion-text-capitalize rating-btn ion-no-margin">
          <ion-icon class="rating-icon" name="thumbs-up" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-row>

      <ion-row *ngIf="form.get('like')?.value === false" class="ion-margin-top pt-sm gap-sm ion-justify-content-start">
        <ion-button (click)="setFeedback(option)" [fill]="form.get('feedback')?.value === option ? 'solid': 'outline'" *ngFor="let option of feedbackNegative" class="ion-text-capitalize ion-no-margin">{{option.name}}</ion-button>
      </ion-row>

      <ion-row *ngIf="form.get('like')?.value === true" class="ion-margin-top pt-sm gap-sm ion-justify-content-start">
        <ion-button (click)="setFeedback(option)" [fill]="form.get('feedback')?.value === option ? 'solid': 'outline'" *ngFor="let option of feedbackPositive" class="ion-text-capitalize ion-no-margin">{{option.name}}</ion-button>
      </ion-row>
    </div>
    <div>
      <ion-button [disabled]="form.invalid" (click)="submit()" class="ion-margin-vertical ion-text-capitalize btn-md" shape="round" expand="block">Continue</ion-button>
    </div>
  </ion-grid>
</ion-content>
