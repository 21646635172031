import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { InfoItem } from "../models/info.model";


@Component({
  templateUrl:'../views/info-item.page.html',
  selector : 'app-info-item',
  styleUrls:['../styles/info-item.page.scss']
})
export class InfoItemPage implements OnInit {
  @Input() title = 'Payment Breakdown';
  @Input() items: InfoItem[] = [
    {
      label: 'Tax',
      description: 'This is required by the SEC',
      value: 4000,
      currencyValue: true
    },
    {
      label: 'Tax',
      description: 'This is required by the SEC',
      value: 3000,
      numericValue: true
    },
  ];

  constructor(private modalCtrl: ModalController){}

  ngOnInit(): void {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
