<ion-content>
    <ion-grid style="min-height: 100%;" class="center">
        <ion-button fill="clear" class="close-btn" (click)="dismiss()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>

        <ion-row *ngIf="(challengeService.challenge$|async)===null">
            <ion-col class="ion-text-center">
                <div>No Challenges Found</div>
            </ion-col>
        </ion-row>

        <ion-row class="ion-text-center">
            <ion-col class="ion-align-self-center">
                <form *ngIf="authenticationType">
                    <!-- <ion-row>
                        <ion-col class="ion-text-center">
                                <h3>{{authenticationType.authType}}</h3>
                        </ion-col>
                    </ion-row> -->
                    <div *ngIf="!showOptionalChallenges && requiredChallenges.length > 0">
                        <div *ngFor="let challenge of requiredChallenges">   
                            <ion-row class="ion-margin-bottom ion-padding-bottom">
                                <ion-col>
                                    <!-- <ion-text></ion-text> -->
                                    <mat-label class="label">{{challenge.userMessage}}</mat-label>
                                </ion-col>
                            </ion-row>
                    
                            <ion-row>
                                <ion-col class="ion-text-center">
                                    <ng-otp-input *ngIf="challenge.textLength>0"
                                    (onInputChange)="onInputChanged($event,challenge)"
                                    [config]="{
                                    length:challenge.textLength,
                                    inputClass: challenge.textLength<=4 ? 'pin-input' : 'tp-input',
                                    allowNumbersOnly:true
                                            }">
                                    </ng-otp-input>
                                </ion-col>
                            </ion-row>
                            <ion-row *ngIf="errorMessage">
                                <ion-col class="ion-padding-top ion-text-center">
                                  <mat-error>
                                  {{errorMessage}}
                                </mat-error>
                                </ion-col>
                              </ion-row>
                            <!-- <ion-row>
                                <ion-col>
                                  <ion-label class="ion-justify-content-center">
                                    <countdown #cd [config]="{ leftTime: 60, format: 'mm:ss', demand: !timer }" (event)="handleEvent($event)"></countdown>
                                  </ion-label>
                                </ion-col>
                              </ion-row> -->
                              <ion-row *ngIf="challenge.name===challengeType.OTP">
                                <ion-col>
                                  <div class="">
                                    <p>Didn't receive code? <a 
                                      *ngIf="resendBtn"
                                      fill="clear"
                                      type="button"
                                      color="primary" 
                                      (click)="onResendOtp()"> Resend now</a></p>
                                  </div>
                                </ion-col>
                              </ion-row>
                        </div>
                    </div>
                    <ion-row *ngIf="showOptionalChallenges && otherChallenges.length >0">
                        <ion-col>
                                <div class="inputGroup">
                                    <ion-list lines="none">
                                        <ion-radio-group>
                                            <ion-list-header>
                                                <ion-label>Select{{authenticationType.challengeCount<2? '': ' 2-factor'}} authentication method</ion-label>
                                            </ion-list-header>
                                            <ion-item class="auth-options" *ngFor="let challenge of otherChallenges;let i=index" [ngClass]="{'active': optionalIndex === i}">
                                                <ion-label class="ion-justify-content-center ion-text-wrap"><sub>{{challenge.description}}</sub></ion-label>
                                                <ion-radio slot="start" [value]="challenge.id" (click)="onUserPreferedOptionSelected(i,challenge)"></ion-radio>
                                            </ion-item>
                                        </ion-radio-group>
                                    </ion-list>
                                </div>
                            </ion-col>
                        </ion-row>
                
                        <ion-row *ngIf="showOptionalChallengesInput && optionalChallenges.length >0">
                            <ion-col class="ion-text-center">
                                <div *ngFor="let challenge of optionalChallenges">
                                <ion-row>
                                    <ion-col>
                                        <mat-label class="label">{{challenge.userMessage}}</mat-label>
                                    </ion-col>
                                </ion-row>
                                <ion-row *ngIf="challenge.textLength>0">
                                    <ion-col>
                                        <ng-otp-input
                                        (onInputChange)="onInputChanged($event,challenge)"
                                        [config]="{
                                        length:challenge.textLength,
                                        inputClass: challenge.textLength<=4 ? 'pin-input' : 'tp-input',
                                        allowNumbersOnly:true
                                                }">
                                        </ng-otp-input>
                                    </ion-col>
                                </ion-row>
                                <!-- <ion-row>
                                    <ion-col>
                                      <ion-label class="ion-justify-content-center">
                                        <countdown #cd [config]="{ leftTime: 60, format: 'mm:ss', demand: !timer }" (event)="handleEvent($event)"></countdown>
                                      </ion-label>
                                    </ion-col>
                                  </ion-row> -->
                                  <ion-row *ngIf="errorMessage">
                                    <ion-col class="ion-padding-top ion-text-center">
                                      <mat-error>
                                      {{errorMessage}}
                                    </mat-error>
                                    </ion-col>
                                  </ion-row>
                                  <ion-row *ngIf="challenge.name===challengeType.OTP">
                                    <ion-col>
                                      <div class="">
                                        <p>Didn't receive code? <a 
                                          *ngIf="resendBtn"
                                          fill="clear"
                                          type="button"
                                          color="primary" 
                                          (click)="onResendOtp()"> Resend now</a></p>
                                      </div>
                                    </ion-col>
                                  </ion-row>
                            </div>
                        </ion-col>
                    </ion-row>
                </form>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>