<ion-content>
  <ion-grid class="ion-margin-horizontal h-100 d-flex flex-column ion-align-items-stretch ion-justify-content-between">
    <div>
      <ion-row>
        <ion-col class="ion-text-center">
          <ion-avatar class="d-inline-block">
            <img alt="Silhouette of a person's head" class="h-100 w-100" [src]="order.store.storeLogo" />
          </ion-avatar>
        </ion-col>
      </ion-row>
      <ion-row class="mt-sm">
        <ion-col class="ion-text-center">
          <ion-label class="ion-no-padding title">How was {{order.store.storeName}}?</ion-label>
        </ion-col>
      </ion-row>
      <ion-row class="mt-sm">
        <ion-col class="ion-text-center">
          <ion-card-subtitle>{{'Your feedback will be shared with the store and they\'ll be able to respond to you.'}}</ion-card-subtitle>
        </ion-col>
      </ion-row>
      <ion-row class="mt-sm">
        <ion-col class="ion-text-center">
          <div class="d-flex ion-align-items-center ion-justify-content-center">
            <ng-container *ngFor="let rating of ratings">
              <ion-icon (click)="setRating(rating.id)" [ngClass]="{'ion-hide': rating.id>(currentRating || 0)}" name="star" class="rating-icon rating-filled"></ion-icon>
              <ion-icon [ngClass]="{'ion-hide': rating.id<=(currentRating || 0)}" (click)="setRating(rating.id)" name="star-outline" class="rating-icon"></ion-icon>

            </ng-container>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
</ion-content>
